import React from 'react'
import $ from 'jquery'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  Badge,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import Modal from '../shared/modal/material_dialog'
import { withSnackbar } from 'notistack'
import Backdrop from '../shared/backdrop'
import AsyncSearchBar from '../shared/react_async_autocomplete'

class AddCoreUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: false,
      list: [],
      errors: {},
      user_roles:[],
      values: this.initialValues()
    }
  }
  initialValues = () => {
    return {
      password: '',
      last_name: '',
      first_name: '',
      phone_number:'',
      user_roles:[]
    }
  }
  handleOpen = (list) => {
    this.setState({ open: true, list: list,  values: this.initialValues(),
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleSubmit = event => {
    let self = this;
    event.preventDefault()
    if (!this.validate()) return;
    let data ={ user_roles: this.state.values.user_roles, password: this.state.values.password, phone_number: this.state.values.phone_number, first_name: this.state.values.first_name,last_name: this.state.values.last_name}
    this.setState({ working: true })
    $.ajax({
      url: `/core_users`,
      type: 'POST',
      data: data,
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('User Successfully Added!', {
          variant: 'success'
        })
        self.setState({ working: false })
        self.props.reloadData()
        self.handleClose()
      },
      error: function (response) {
        self.setState({ working: false })
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
      }
    })
  }

  handleChange = e => {
    var array = this.state.values.user_roles
    const { name, checked } = e.target
    if (checked) {
      this.setState({
        values: { ...this.state.values, user_roles: array.concat(name) }
      })
    } else {
      var itemsToDelete = array.filter(word => word === name)
      let newArray = array.filter(x => !itemsToDelete.includes(x))
      this.setState({ values: { ...this.state.values, user_roles: newArray } })
    }
  }
  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ values: { ...this.state.values, [name]: value } })
		this.validate({[name]: value});
  }
  validate = (fieldValues = this.state.values) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = '';
    if ('phone_number' in fieldValues) {
      temp.phone_number = fieldValues.phone_number ? '' : 'Required';
    }
    if ('first_name' in fieldValues) {
      temp.first_name = fieldValues.first_name ? '' : 'Required';
    }
    if ('password' in fieldValues) {
      temp.password = fieldValues.password ? '' : 'Required';
    }
    if ('last_name' in fieldValues) {
      temp.last_name = fieldValues.last_name ? '' : 'Required';
    }
    
    this.setState({ errors: { ...temp } })
    if (fieldValues === this.state.values)
      return Object.values(temp).every(x => x === '')
  }


  render() {
    const { classes } = this.props
    const body = (
      <>
        
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
  <input type="text" name="phone_number"  class="form-control" id="phone_number" placeholder="" value={this.state.values.phone_number} onChange={this.handleInputChange}></input>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Password</label>
  <input type="password" name="password" class="form-control" id="password" placeholder="" value={this.state.values.password} onChange={this.handleInputChange}></input>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">First Name</label>
  <input type="text" name="first_name" class="form-control" id="first_name" placeholder="" value={this.state.values.first_name} onChange={this.handleInputChange}></input>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Last Name</label>
  <input type="text" name="last_name" class="form-control" id="last_name" placeholder="" value={this.state.values.last_name} onChange={this.handleInputChange}></input>
</div>
<label for="exampleFormControlInput1" class="form-label">Roles</label>

          <Card className={classes.root} style={{ minHeight: '30vh' }}>
            <Backdrop open={this.state.working} />

            <div className='container pb-5'>
              <div className='row'>
                {this.state.list.map(
                  m =>
                    this.state.list.indexOf(m) % 3 === 0 && (
                      <div className='col-4'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.values.user_roles.indexOf(m[0]) !== -1
                              }
                              onChange={this.handleChange}
                              name={m[0]}
                              color='primary'
                            />
                          }
                          label={m[1]}
                        />
                      </div>
                    )
                )}
                {this.state.list.map(
                  m =>
                    this.state.list.indexOf(m) % 3 === 1 && (
                      <div className='col-4'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.values.user_roles.indexOf(m[0]) !== -1
                              }
                              onChange={this.handleChange}
                              name={m[0]}
                              color='primary'
                            />
                          }
                          label={m[1]}
                        />
                      </div>
                    )
                )}
                {this.state.list.map(
                  m =>
                    this.state.list.indexOf(m) % 3 === 2 && (
                      <div className='col-4'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.values.user_roles.indexOf(m[0]) !== -1
                              }
                              onChange={this.handleChange}
                              name={m[0]}
                              color='primary'
                            />
                          }
                          label={m[1]}
                        />
                      </div>
                    )
                )}
              </div>
             
              
            
            </div>
          </Card>
        
      </>
    )
    const actions = (
      <Button
        variant='contained'
        type='submit'
        style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
        disabled={this.state.working}
        onClick={this.handleSubmit}
      >
        {this.state.working ? 'Working...' : 'Submit'}
      </Button>
    )

    return (
      <Modal
        isOpen={this.state.open}
        title={"Add User"}
        handleClose={this.handleClose}
        minHeight='40vh'
        maxHeight='60%'
        maxWidth='sm'
        actions={actions}
      >
        {body}
      </Modal>
    )
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
})

export default withSnackbar(withStyles(useStyles)(AddCoreUser))
