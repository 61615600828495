import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import $ from "jquery";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Badge,
  IconButton,
  TextField,
} from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import { BsFillChatQuoteFill } from "react-icons/bs";
import CardTimeline from "./_note_time_line";
import { useSnackbar } from "notistack";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

interface NoteProps {
  key?: any;
  order_item_stage_id?: any;
  order_id?: any;
  source?: any;
  newNoteChild?: any;
  notes_count?: any;
  orderNumber?: any;
}

const NoteForm = forwardRef(({
  key,
  order_item_stage_id,
  order_id,
  source,
  newNoteChild,
  notes_count,
  orderNumber,
}: NoteProps, ref: any) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<any>([]);
  const [order_item_stage_ids, setOrderItemStageId] = useState<any>(null);
  const [orderId, setOrderId] = useState<any>();
  const [note, setNote] = useState<string>("");
  const [errors, setErrors] = useState<any>([]);
  const [global, setGlobal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [sources, setSource] = useState<any>();
  const [allStates, setAllStates] = useState<any>();

  useEffect(() => {
    setAllStates({
      open,
      notes,
      order_item_stage_id: order_item_stage_ids,
      order_id: orderId,
      note,
      errors,
      global,
      loading,
      submitting,
      source: sources,
    });
  }, [
    open,
    notes,
    order_item_stage_ids,
    orderId,
    note,
    errors,
    global,
    loading,
    submitting,
    sources,
  ]);

  useImperativeHandle(ref, () => ({
    handleOpen(order_item_stage_id: any, inputSource = null) {
      console.log("order_item_stage_id", order_item_stage_id);
      setOpen(true);
      setOrderItemStageId(order_item_stage_id || order_item_stage_ids);
      setOrderId(order_id);
      setSource(inputSource || source);
      setErrors([]);
      getNotes(order_item_stage_id, inputSource || source);
    }
  }))

  const handleOpen = (order_item_stage_id: any, inputSource = null) => {
      setOpen(true);
    setOrderItemStageId(order_item_stage_id || order_item_stage_ids);
    setOrderId(order_id);
    setSource(inputSource || source);
    setErrors([]);
    getNotes(order_item_stage_id, inputSource || source);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNotes = (order_item_stage_id: any, source: any) => {
    let url;
    setLoading(true);
    url = order_id
      ? `/orders/notes.json?id=${order_id}&source=${source}`
      : order_item_stage_id
      ? `/order_item_stages/notes?id=${order_item_stage_id}&source=${source}`
      : `/stages/get_item_stage_notes?id=${order_item_stage_id}&source=${source}`;
    if (Number(order_item_stage_id)) {
      url = `/order_item_stages/${order_item_stage_id}/notes.json?source=${source}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        setNotes(result.notes);
      });
  };

  const validate = (fieldValues = allStates) => {
    let temp = { ...errors };
    temp.serverErrors = "";
    if ("note" in fieldValues) {
      temp.note = fieldValues.note ? "" : "Required";
    }
    setErrors({ ...temp });

    if (fieldValues === allStates) {
      return Object.values(temp).every((x) => x === "");
    } else {
      return true;
    }
  };

  const handleSubmit = (event: any) => {
    let self = this;
    event.preventDefault();
    console.log(validate());
    if (!validate()) return;
    setSubmitting(true);
    const { note, order_item_stage_id, global, source } = allStates;
    const data = { note: note, global: global, order_number: orderNumber };

    $.ajax({
      url: order_id
        ? `/orders/${orderId}/create_note.json?source=${sources}`
        : `/order_item_stages/create_note?id=${order_item_stage_id}&source=${source}`,
      type: "POST",
      data: data,
      dataType: "json",
      success: function (response) {
        setSubmitting(false);
        setNote("");
        newNoteChild(note);
        handleClose();
      },
      error: function (response) {
        setErrors({ ...{ serverErrors: response.responseJSON.error } });
        setSubmitting(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNote(value);
    validate({ [name]: value });
    setAllStates({ ...allStates, note: value });
  };

  const handleCheckBoxChange = (e) => {
    setGlobal(e.target.checked);
  };

  const body = loading ? <CircularProgress /> : <CardTimeline notes={notes} />;

  const actions = (
    <>
      <TextField
        name="note"
        label="Note"
        style={{ margin: 8 }}
        value={note}
        fullWidth
        multiline
        disabled={submitting}
        onChange={handleInputChange}
        {...(errors.note && {
          error: true,
          helperText: errors.note,
        })}
        margin="normal"
      />
      <div className="form-check form-switch mt-4">
        <label className="form-check-label" htmlFor="global">
          Global
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          id="global"
          name="global"
          checked={global}
          onClick={handleCheckBoxChange}
        />
      </div>
      {submitting ? (
        <CircularProgress className="mt-3" />
      ) : (
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          type="submit"
          className="mt-3"
          disabled={submitting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      )}
    </>
  );

  return (
    <>
      {(order_item_stage_id || order_id) && (
        <IconButton onClick={() => handleOpen(order_item_stage_id)}>
          <Badge badgeContent={notes_count} color="secondary">
            <BsFillChatQuoteFill style={{ fontSize: 25, color: blue[500] }} />
          </Badge>
        </IconButton>
      )}
      <Modal
        disableBackdropClick
        isOpen={open}
        title="Notes"
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="60%"
        maxWidth="sm"
        actions={actions}
      >
        {loading ? <CircularProgress /> : body}
      </Modal>
    </>
  );
});

export default NoteForm;
