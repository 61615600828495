import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import Modal from "../shared/modal/material_dialog";
import { MdChangeHistory } from "react-icons/md";
import CardTimeline from "../notes/_note_time_line";

const ShowIssues = ({ order_item_stage_id, source, hidden, table }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [issues, setIssues] = useState<any>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  const [allStates, setAllStates] = useState<any>();

  useEffect(() => {
    setAllStates({
      open,
      working,
      issues,
      errors,
    });
  }, [working, open, issues, errors]);

  const handleOpen = () => {
    setOpen(true);
    getIssues(order_item_stage_id, source);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getIssues = (order_item_stage_id: any, source: any) => {
    setWorking(true);
    fetch(
      `/order_item_stages/${order_item_stage_id}/issues.json?source=${source}`
    )
      .then((response) => response.json())
      .then((result) => {
        setIssues(result.issues);
        setWorking(false);
      });
  };

  const validate = (fieldValues = allStates) => {
    let temp = { ...errors };
    temp.serverErrors = "";
    if ("note" in fieldValues) {
      temp.note = fieldValues.note ? "" : "Required";
    }
    setErrors({ ...temp });

    if (fieldValues === allStates)
      return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    // this.setState({ [name]: value });
    validate({ [name]: value });
  };

  const body = <CardTimeline notes={issues} />;

  return !open ? (
    <IconButton
      onClick={handleOpen}
      style={{
        position: table ? "" : "absolute",
        margin: table ? "" : "1.5rem -2.5rem 0rem 0rem",
        top: table ? "" : "0px",
        right: table ? "" : "0px",
      }}
      hidden={hidden}
    >
      <MdChangeHistory color="secondary" />
    </IconButton>
  ) : (
    <Modal
      disableBackdropClick
      isOpen={open}
      title="Notes"
      handleClose={handleClose}
      minHeight="40vh"
      maxHeight="60%"
      maxWidth="md"
    >
      {working ? <CircularProgress /> : body}
    </Modal>
  );
};

export default ShowIssues;
