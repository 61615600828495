import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import Table from "../../shared/tables/material_table";
import Backdrop from "../../shared/backdrop";
import { FiRefreshCw } from "react-icons/fi";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import AddTrackingFrom from "./_shipping_form";
import PackingListForm from "./_packing_list_form";
import PackingListForm2 from "./_packing_list_form2";
import AddToShipment from "./_add_to_shipment";
import PackingList from "./_packing_list";
import pureIcons from "../../shared/pure_icons";
import { BsPlusLg } from "react-icons/bs";
import { FaFileInvoice, FaShippingFast } from "react-icons/fa";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { Switch, styled } from "@material-ui/core";

const ReadyToShip = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [shipments, setShipments] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [open, setOpen] = useState<any>(false);
  const [groupId, setGroupId] = useState<any>("");

  const _PackingListForm = useRef<any>(null);
  const _PackingListForm2 = useRef<any>(null);
  const _shippingFormRef = useRef<any>(null);
  const _AddToShipment = useRef<any>(null);
  const [orderState, setOrderState] = useState<boolean>(true);
  const sourceDestination = useRef<any>({
    source: "",
    destination: "",
  });

  const getData = () => {
    setLoading(true);
    const url = `/order_item_stages/ready_to_ship.json?source=${
      sourceDestination.current.source
    }&location=${
      sourceDestination.current.destination
    }&sea_shipping=${!orderState}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(
              "You need to sign in or sign up before continuing."
            );
          }
        }
        return response.json();
      })
      .then((data) => {
        if (data?.error) {
          enqueueSnackbar(
            "Something went wrong, you are redirected to home page",
            {
              variant: "error",
            }
          );

          setTimeout(() => {
            history.push("/");
          }, 500);
          return;
        }
        setLoading(false);
        setShipments(data);

        // setShipments(responseData);
        const breadcrumb = data?.breadcrumb.filter(
          (value) => value.name === "Ready to Ship"
        );
        window?.breadcrumb.addBreadcrumb(breadcrumb);
      })
      .catch((error) => {
        if (
          error.message === "You need to sign in or sign up before continuing."
        ) {
          window.location.href = "/";
        } else {
          console.error("Error:", error);
        }
      });
  };
  useEffect(() => {
    const source = localStorage.getItem("source");
    const destination = localStorage.getItem("destination");

    if (source) sourceDestination.current.source = source;
    if (destination) sourceDestination.current.destination = destination;

    getData();
  }, [orderState]);

  const reloadData = () => {
    getData();
  };
  console.log(shipments);
  const handleUpdate = (newValue: any, data: any, columnDef: any) => {
    if (newValue === "" || newValue === null) return;
    let payload: any, url: any;

    switch (columnDef.field) {
      case "weight":
        url = `/order_item_stages/update_stage_tracking.json?source=${sourceDestination.current.source}`;
        payload = {
          tracking: data.tracking,
          new_tracking: `${data.group_id}*${newValue}*${data.dimensions}`,
          stage: "ready_to_ship",
        };
        break;
      case "dimensions":
        url = `/order_item_stages/update_stage_tracking.json?source=${sourceDestination.current.source}`;
        payload = {
          tracking: data.tracking,
          new_tracking: `${data.group_id}*${data.weight}*${newValue}`,
          stage: "ready_to_ship",
        };
        break;
      case "status":
        url = `/order_item_stages/update_stage.json?source=${sourceDestination.current.source}`;
        payload = {
          status: newValue,
          group_id: data.group_id,
          stage: "ready_to_ship",
        };
        break;
      default:
    }

    setLoading(true);

    $.ajax({
      url: url,
      type: "PATCH",
      data: payload,
      dataType: "json",
      success: function (response) {
        reloadData();
        enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        enqueueSnackbar(data.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const columns = [
    {
      title: "Box Number",
      field: "group_id",
      sorting: false,
      editable: "never",
      filtering: false,
    },
    {
      title: "Items QTY",
      field: "items_qty",
      sorting: false,
      editable: "never",
      filtering: false,
    },
    {
      title: "Weight",
      field: "weight",
      sorting: false,
      filtering: false,
    },
    {
      title: "Dimensions",
      field: "dimensions",
      sorting: false,
      filtering: false,
    },
    {
      title: "Processed By",
      field: "who_did_it",
      sorting: false,
      editable: "never",
      filtering: false,
    },
    {
      title: "Status",
      field: "status",
      sorting: false,
      lookup: {
        Ready: "Ready",
        Ticket: "Ticket",
        Approved: "Approved",
        Return: "Return",
        Other: "Other",
      },
    },
    {
      title: "Date Created",
      field: "created_at",
      editable: "never",
      filtering: false,
    },
  ];

  const IOSSwitch = styled((props: any) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 80,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(53.5px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#f6b67f" : "#d9d8d8",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "dark" ? "#40C071" : "green",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const switchAction = () => {
    setOrderState(!orderState);
  };

  return (
    <>
      <div className="bg-white rounded">
        <Backdrop open={loading} />
        {open && (
          <PackingList
            open={open}
            setOpen={setOpen}
            groupId={groupId}
            setGroupId={setGroupId}
            orderState={orderState}
          />
        )}
        <PackingListForm
          ref={_PackingListForm}
          source={sourceDestination.current.source}
          location={sourceDestination.current.destination}
        />
        <PackingListForm2
          ref={_PackingListForm2}
          source={sourceDestination.current.source}
          location={sourceDestination.current.destination}
        />
        <AddToShipment
          ref={_AddToShipment}
          reloadData={reloadData}
          source={sourceDestination.current.source}
          location={sourceDestination.current.destination}
        />
        <AddTrackingFrom
          ref={_shippingFormRef}
          activeCarriers={shipments?.active_carriers}
          reloadData={reloadData}
          source={sourceDestination.current.source}
          location={sourceDestination.current.destination}
        />
        <Table
          title={
            <>
              <span
                style={{
                  marginRight: "2rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Ready to Ship
              </span>
              <span onClick={() => switchAction()}>
                <span
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    color: orderState ? "#315e5a" : "white",
                    fontSize: "1rem",
                    marginLeft: orderState ? "1rem" : "2rem",
                  }}
                >
                  {orderState ? "Sea" : "Sea"}
                </span>
                <IOSSwitch sx={{ m: 1 }} checked={orderState} />
              </span>
            </>
          }
          options={{
            toolbar: true,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            selection: true,
            padding: "1rem",
            cellStyle: {
              padding: "1rem",
            },
            searchAutoFocus: true,
          }}
          columns={columns}
          data={shipments?.shipments || []}
          cellEditable={{
            cellStyle: {
              width: "15%",
            },
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve: any, reject) => {
                setTimeout(() => {
                  handleUpdate(newValue, rowData, columnDef);
                  resolve();
                }, 500);
              });
            },
          }}
          actions={[
            // {
            //   icon: () => (
            //     <>
            //       <span
            //         style={{
            //           position: "absolute",
            //           zIndex: 1,
            //           color: orderState ? "#315e5a" : "white",
            //           fontSize: "1rem",
            //           marginRight: orderState ? "1rem" : "-1rem",
            //         }}
            //       >
            //         {orderState ? "Sea" : "Sea"}
            //       </span>
            //       <IOSSwitch
            //         sx={{ m: 1 }}
            //         defaultChecked
            //         checked={orderState}
            //       />
            //     </>
            //   ),
            //   onClick: () => {
            //     switchAction();
            //   },
            //   isFreeAction: true,
            //   tooltip: "Shipping Options",
            // },
            {
              icon: () => <FiRefreshCw />,
              onClick: () => reloadData(),
              isFreeAction: true,
              tooltip: "Refresh",
            },
            {
              tooltip: "Create Packing List",
              icon: () => (
                <span style={{ padding: "0rem .5rem 0rem .8rem" }}>
                  <FaFileInvoice />
                </span>
              ),
              onClick: (event, data) => {
                return _PackingListForm.current.handleOpen(
                  data.map((p) => p.group_id)
                );
              },
            },
            {
              icon: () => (
                <span style={{ padding: "0rem .5rem" }}>
                  <FaShippingFast />
                </span>
              ),
              tooltip: "Add Carrier Tracking",
              onClick: (event, data) => {
                _shippingFormRef.current.handleOpen(data);
              },
            },
            {
              icon: () => (
                <span style={{ padding: "0rem .5rem" }}>
                  <pureIcons.Edit />
                </span>
              ),
              tooltip: "Show packing list details",
              // onClick: (event, rowData) => editGridData(rowData.group_id),
              onClick: (event, rowData) => {
                setOpen(true);
                setGroupId(rowData.group_id);
              },
              position: "row",
            },
            {
              icon: () => (
                <span style={{ padding: "0rem .5rem" }}>
                  <BsPlusLg />
                </span>
              ),
              tooltip: "Add to Shipment",
              onClick: (event, data) => {
                _AddToShipment.current.handleOpen(data.map((p) => p.group_id));
              },
            },
            {
              tooltip: "Detailed Packing List",
              icon: () => (
                <span style={{ padding: "0rem .5rem" }}>
                  <FaFileInvoiceDollar />
                </span>
              ),
              onClick: (event, data) =>
                _PackingListForm2.current.handleOpen(
                  data.map((p) => p.group_id)
                ),
            },
            {
              tooltip: "Bulk Processing",
              icon: () => (
                <span style={{ padding: "0rem .5rem" }}>
                  <pureIcons.Export />
                </span>
              ),
              onClick: (event, data) =>
                window.open(
                  `/reports/shipping_list_summary?source=${
                    sourceDestination.current.source
                  }&location=${
                    sourceDestination.current.destination
                  }&group_ids=${data.map((p) => p.group_id)}`,
                  "_self"
                ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default ReadyToShip;
