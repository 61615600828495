import React, { useRef, useState } from "react";
import Table from "../shared/tables/material_table";
import { Badge } from "@material-ui/core";
import ShowItem from "../order_items/show";
import { ImInfo } from "react-icons/im";

const ShowOrderStages = ({ source, order_number, callBack }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [order_numbers, setOrderNumber] = useState<string>("");
  const [notes_count, setNotesCount] = useState<number>(0);
  const [order, setOrder] = useState<any>({ vendors: [] });
  const [loading, setLoading] = useState<boolean>(true);

  const _ShowItemRef = useRef<any>();

  const columns = [
    {
      title: "",
      field: "image",
      grouping: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "60px", width: "60px", objectFit: "contain" }}
              alt={row.name}
              src={row.image}
            ></img>
          </Badge>
        </div>
      ),
    },
    {
      title: "Product Name",
      field: "name",
      sorting: false,
      cellStyle: { whiteSpace: "normal", wordWrap: "break-word", width: "30%" },
    },
    { title: "Stage", field: "stage", defaultGroupOrder: 0 },
    { title: "Vendor", field: "vendor" },
    { title: "QTY", field: "stage_qty", grouping: false },
    {
      title: "Tracking/Invoice",
      field: "tracking",
      cellStyle: { whiteSpace: "normal", wordWrap: "break-word", width: "20%" },
    },
    { title: "Date", field: "date", grouping: false },
  ];

  return (
    <>
      <ShowItem ref={_ShowItemRef} /> {/*  reloadData={this.reloadData} */}
      <Table
        options={{
          toolbar: false,
          filtering: false,
          sorting: false,
          grouping: true,
          paging: false,
          actionsColumnIndex: -1,
          debounceInterval: 1000,
        }}
        columns={columns}
        actions={[
          {
            icon: () => <ImInfo />,
            tooltip: <span>Show</span>,
            onClick: (event, rowData) =>
              _ShowItemRef.current.handleOpen(rowData.order_item_id, source),
            position: "row",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            let url = `/orders/show_order.json?order_number=${order_number}&by_stage=true&source=${source}&`;
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.stages.flat(),
                  page: result.page - 1,
                  totalCount: result.total,
                });
                callBack(result.new_customer);
                window.breadcrumb.addBreadcrumb(result.breadcrumb);
              });
          })
        }
      />
    </>
  );
};

export default ShowOrderStages;
