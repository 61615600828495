import React, { useRef, useState } from "react";
import $ from "jquery";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import NoteForm from "../../notes/_note_form";
import DeleteItem from "../../order_items/_cancel_form";
import { Checkbox } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { useSnackbar } from "notistack";
import InfoIcon from "@material-ui/icons/Info";
import ShowItem from "../../order_items/show";
import { green } from "@material-ui/core/colors";

const SpeedDials = ({
  product,
  source,
  newNote,
  callBackLockState,
  handleDeleteItem,
}: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const _ShowItemRef = useRef<any>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleLockChange = (e) => {
    $.ajax({
      url: `/order_item_stages/switch_lock?id=${product.order_item_stage_id}&source=${source}`,
      type: "POST",
      dataType: "json",
      success: function (response) {
        callBackLockState(response.state === "locked");
        enqueueSnackbar(`successfully updated!`, {
          variant: "success",
        });
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const actions = [
    {
      icon: (
        <NoteForm
          order_item_stage_id={product.order_item_stage_id}
          key={product.order_item_stage_id}
          newNoteChild={newNote}
          source={source}
        />
      ),
      name: "Add note",
    },
    {
      icon: (
        <InfoIcon
          onClick={() =>
            _ShowItemRef.current.handleOpen(product.order_item_id, source)
          }
        />
      ),
      name: "Show",
    },
    {
      icon: (
        <Checkbox
          icon={<LockIcon />}
          checkedIcon={<LockOpenIcon style={{ color: green[500] }} />}
          onChange={handleLockChange}
          defaultChecked={product.locked}
        />
      ),
      name: "Lock",
    },
    {
      icon: (
        <DeleteItem
          product={product}
          callBackDeleteItem={handleDeleteItem}
          qty={product.qty}
          stage={"Received"}
          order_item_stage_id={product.order_item_stage_id}
          source={source}
        />
      ),
      name: "Delete",
    },
  ];
  return (
    <>
      <ShowItem ref={_ShowItemRef} />
      <SpeedDial
        FabProps={{ size: "small", color: "default" }}
        ariaLabel="More actions"
        className={"classes.speedDial"}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={"down"}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </>
  );
};

export default SpeedDials;
