import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import { Button, TextField, Box, IconButton } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import ProductCard from "./_shipping_card";
import Typography from "@material-ui/core/Typography";
import DatePicker from "../../shared/date_picker";
import ProductCardSkeleton from "../../shared/skeletons/product_card_skeleton";
import ShowOrder from "../_show";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import $ from "jquery";
import OrderInfo from "../received/_order_info";
import Backdrop from "../../shared/backdrop";
import Alert from "@material-ui/lab/Alert";
import CopyTextComponent from "../../shared/copy_clipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {},
}));

const ShippingForm = forwardRef(({ reloadData }: any, ref: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [order_number, setOrderNumber] = useState<any>("");
  const [orderSummary, setOrdersSummary] = useState<any>([]);
  const [vendor, setVendor] = useState<any>("");
  const [order_id, setOrderId] = useState<any>("");
  const [shipped, setShipped] = useState<any>([]);
  const [eta, setEta] = useState<any>(null);
  const [tracking, setTracking] = useState<any>("");
  const [working, setWorking] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<any>();
  const [group_id, setGroupId] = useState<any>();
  const [source, setSource] = useState<any>();
  const [duplicate, setDuplicate] = useState<boolean>(false);
  const [market_source, setMarketSource] = useState<any>();
  const [new_customer, setNewCustomer] = useState<any>();
  const [allStates, setAllStates] = useState<any>();
  const [shipping_option, setShipping_option] = useState<any>();
  const _ShowOrder = useRef<any>(null);
  const [OrderState, setOrderState] = useState<any>();

  const classes = useStyles();

  useEffect(() => {
    setAllStates({
      open,
      errors,
      products,
      order_number,
      orderSummary,
      vendor,
      order_id,
      shipped,
      eta,
      tracking,
      working,
      invoice,
      group_id,
      source,
      duplicate,
      market_source,
      new_customer,
    });
  }, [
    open,
    errors,
    products,
    order_number,
    orderSummary,
    vendor,
    order_id,
    shipped,
    eta,
    tracking,
    working,
    invoice,
    group_id,
    source,
    duplicate,
    market_source,
    new_customer,
  ]);

  useImperativeHandle(ref, () => ({
    handleOpen(
      invoice,
      order_number,
      vendor,
      order_id,
      source,
      group_id,
      market_source,
      new_customer,
      shipping_option,
      orderState
    ) {
      setOpen(true);
      setOrderNumber(order_number);
      setInvoice(invoice);
      setVendor(vendor);
      setOrderId(order_id);
      setErrors([]);
      setGroupId(group_id);
      setSource(source);
      setDuplicate(false);
      setEta(null);
      setMarketSource(market_source);
      setNewCustomer(new_customer);
      setShipping_option(shipping_option);
      setOrderState(orderState);
      getProducts(invoice, source, orderState);
    },
  }));

  const selectedDateValue = (date: any) => {
    setEta(date);
    validate({ eta: date });
  };

  const handleClose = () => {
    setOpen(false);
    const searchBar: any = document.querySelector(
      `#pure_table_toolbar_id input`
    );
    if (searchBar) searchBar?.focus();
  };

  const getProducts = (invoice: any, source: any, orderState: any) => {
    setWorking(true);
    const url = `/order_item_stages/items_to_ship?source=${source}&invoice=${invoice}&shipping_option=${orderState}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        setProducts(result.products);
        setWorking(false);
        setShipped([]);
        setTracking("");
        setEta(null);
        setOrdersSummary(result.order_summary);
      })
      .catch((error) => {
        setWorking(false);
        enqueueSnackbar(
          "Invalid invoice or items has been moved to the next stage",
          { variant: "error" }
        );
        handleClose();
      });
  };

  const handleAfterSubmit = () => {
    if (
      shipped.length === products.length &&
      shipped.reduce((a: any, v: any) => (a = a + v.qty), 0) ===
        products.reduce((a: any, v: any) => (a = a + v.qty), 0)
    ) {
      handleClose();
    } else {
      getProducts(invoice, source, OrderState);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setTracking(value);
    validate({ [name]: value });
  };

  const validate = (fieldValues = allStates) => {
    let temp = { ...errors };
    temp.serverErrors = "";
    if ("tracking" in fieldValues) {
      temp.tracking = fieldValues.tracking ? "" : "Required";
    }
    if ("eta" in fieldValues) {
      temp.eta =
        fieldValues.eta === null
          ? "Required"
          : isNaN(fieldValues.eta.getTime())
          ? "Invalid Date"
          : "";
    }
    if ("shipped" in fieldValues) {
      if (fieldValues.shipped.length > 0) temp.shipped = "";
      else {
        temp.shipped = "One or more items must be selected!";
        enqueueSnackbar("One or more items must be selected!", {
          variant: "error",
        });
      }
    }
    setErrors({ ...temp });

    if (fieldValues === allStates)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!validate()) return;
    setWorking(true);

    const data = {
      shipped: shipped,
      market_source: market_source,
      tracking: tracking,
      group_id: group_id,
      invoice: invoice,
      order_number: order_number,
      vendor: vendor,
      eta: eta,
      source: source,
      duplicate,
      new_customer: new_customer,
      shipping_option: shipping_option,
    };
    $.ajax({
      url: `/orders/ship?order_number=${order_number}&shipping_option=${shipping_option}`,
      type: "POST",
      data: data,
      dataType: "json",
      success: function (response) {
        setWorking(false);
        setDuplicate(false);
        enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        reloadData(true);
        handleAfterSubmit();
      },
      error: function (response) {
        if (
          JSON.parse(response.responseJSON.error).error.trim() ===
          "tracking number already exists"
        ) {
          setWorking(false);
          setDuplicate(true);
        } else {
          enqueueSnackbar(
            JSON.parse(response.responseJSON.error).error.trim(),
            {
              variant: "error",
            }
          );
          setWorking(false);
        }
      },
    });
  };

  const setProductData = (data: any) => {
    if (data.selected) {
      let obj = shipped.find(
        (o) => o.order_item_stage_id === data.order_item_stage_id
      );
      if (obj) {
        let replace = shipped.filter(
          (o) => o.order_item_stage_id !== data.order_item_stage_id
        );
        replace.push(data);
        setShipped(replace);
      } else {
        setShipped([...shipped, data]);
      }
    } else {
      if (data.deleted) {
        let replace = products.filter(
          (o) => o.order_item_stage_id !== data.order_item_stage_id
        );
        if (products.length === 1) {
          handleClose();
          reloadData(true);
        } else {
          setProducts(replace);
        }
      }
      let replace = shipped.filter(
        (o) => o.order_item_stage_id !== data.order_item_stage_id
      );
      setShipped(replace);
    }
  };

  const body = products.map((p: any) => (
    <ProductCard
      product={p}
      callBack={setProductData}
      key={p.order_item_stage_id}
      selected={shipped.find(
        (o) => o.order_item_stage_id === p.order_item_stage_id
      )}
      source={source}
    />
  ));

  const summary = (
    <div className="container">
      <div className="row ms-4">
        <div className="col">
          <Box pt={2} mt={1}>
            <Typography variant="button" color="textSecondary">
              Selected: {shipped.length}/{products.length}
            </Typography>
          </Box>
        </div>
        <div className="col">
          <TextField
            fullWidth
            name="tracking"
            value={tracking}
            label="Tracking number"
            {...(errors.tracking && {
              error: true,
              helperText: errors.tracking,
            })}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <DatePicker
            disablePast={true}
            disableFuture={false}
            name="eta"
            label="ETA"
            selectedDateValue={selectedDateValue}
            value={eta}
            {...(errors.eta && {
              error: true,
              helperText: errors.eta,
            })}
          />
        </div>
      </div>
    </div>
  );

  const actions = (
    <>
      {duplicate && (
        <Alert severity="error">
          Tracking number already exist in the system!
        </Alert>
      )}
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        className={classes.button}
        onClick={handleSubmit}
      >
        {duplicate ? "Submit duplicate" : "Submit"}
      </Button>
    </>
  );

  const orderTitle = (
    <>
      <Button
        style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}
        onClick={() => _ShowOrder.current.handleOpen(order_number, source)}
      >
        {order_number}
      </Button>
      <IconButton
        onClick={() => navigator.clipboard.writeText(order_number)}
        aria-label="copy"
      >
        <CopyTextComponent textValue={order_number} />
      </IconButton>
      <OrderInfo orderSummary={orderSummary} />
      {`(${vendor} ${invoice})`}
    </>
  );

  return (
    <div>
      <Backdrop open={working} />
      <ShowOrder ref={_ShowOrder} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={orderTitle}
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
        summary={summary}
        actions={actions}
      >
        {working ? <ProductCardSkeleton /> : body}
      </Modal>
    </div>
  );
});

export default ShippingForm;
