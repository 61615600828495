import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ShowVendors from "./_show_vendors";
import ShowStages from "./_show_stages";
function TabPanel(props: any) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabsButton({ order_number, source, callBack, orderInfo }: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="auto tabs"
      >
        <Tab label="By Vendor" {...a11yProps(0)} />
        <Tab label="By Stage" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ShowVendors
          order_number={order_number}
          source={source}
          callBack={callBack}
          ororderInfo={orderInfo}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ShowStages order_number={order_number} source={source} />
      </TabPanel>
    </div>
  );
}
