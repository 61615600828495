import React, { forwardRef, useImperativeHandle, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, MenuItem, TextField } from "@material-ui/core";
import Select from "../../shared/autocomplete";
import Modal from "../../shared/modal/material_dialog";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 5,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    objectFit: "contain",
  },
  button: {},
}));

const AddToListForm = forwardRef(
  ({ reloadData, shipping_methods, source, location }: any, ref) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>([]);
    const [values, setValues] = useState<any>();
    const [tracking, setTracking] = useState<any>(null);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [shipping_method, setShippingMethod] = useState<any>();

    const handleClose = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      handleOpen(data) {
        setOpen(true);
        setValues(data);
        setErrors([]);
        setTracking(null);
      },
    }));

    const validate = (fieldValues = tracking) => {
      let temp = { ...errors };
      temp.tracking = fieldValues ? "" : "Required";
      setErrors({ ...temp });
      if (fieldValues === tracking)
        return Object.values(temp).every((x) => x === "");
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      if (!validate()) return;
      setSubmitting(true);
      $.ajax({
        url: `/order_item_stages/add_to_shipment?source=${source}&location=${location}`,
        type: "POST",
        data: { data: values, tracking: tracking },
        dataType: "json",
        success: function (response) {
          setSubmitting(false);
          enqueueSnackbar("successfully processed!", {
            variant: "success",
          });
          reloadData();
          handleClose();
        },
        error: function (response) {
          enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
          setSubmitting(false);
        },
      });
    };

    const handleSelectCallBack = (data) => {
      setTracking(data.value);
      validate({ data });
    };

    const actions = (
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        className={classes.button}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    );

    const body = (
      <Card className={classes.root} style={{ minHeight: "30vh" }}>
        <div className="container pb-5">
          <p>
            You have selected {values?.length} box
            {values?.length > 1 ? "es" : ""} to add to an existing shipment
          </p>
          <div className="row pt-2">
            <Select
              name="tracking"
              label="Tracking"
              url={`/order_item_stages/in_transit_list.json?source=${source}&location=${location}`}
              error={errors.tracking}
              callBack={handleSelectCallBack}
            />
          </div>
          {shipping_methods?.length > 1 && (
            <div className="row pt-3">
              <div className="col">
                <TextField
                  label="Shipping Method"
                  select
                  fullWidth
                  value={shipping_method || ""}
                  {...(errors.shipping_method && {
                    error: true,
                    helperText: errors.shipping_method,
                  })}
                  onChange={(event) => setShippingMethod(event.target.value)}
                >
                  {shipping_methods?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}
        </div>
      </Card>
    );

    return (
      <>
        <Backdrop open={submitting} />
        <Modal
          disableBackdropClick
          isOpen={open}
          title="Add to Shipment"
          handleClose={handleClose}
          maxHeight="400%"
          maxWidth="sm"
          actions={actions}
        >
          {body}
        </Modal>
      </>
    );
  }
);

export default AddToListForm;
