import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Popover, IconButton, Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import pureIcons from '../../shared/pure_icons';

const useStyles = makeStyles((theme: any) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover(customerInfo: any) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick}>
        <pureIcons.CgProfile/>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {width: '30%'},
        }}
      >
        <div className='p-3'>
          <Typography variant='subtitle1' color='textSecondary'>
            ID: <b>{customerInfo.customerInfo?.user_id}</b>
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
            Name: <b>{customerInfo.customerInfo?.name}</b>
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
            Phone: <b>{customerInfo.customerInfo?.phone_number}</b>
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
            Kidus: <b>{customerInfo.customerInfo?.kidus}</b>
          </Typography>
          <div className='row'>
						<div className='col-5'>
          <Typography variant='subtitle1' color='textSecondary'>
            Alley: <b>{customerInfo.customerInfo?.alley}</b>
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
          House Number: <b>{customerInfo.customerInfo?.house_number}</b>
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
          Neighborhood: <b>{customerInfo.customerInfo?.neighborhood}</b>
          </Typography></div>
            <div className='col-6'>
          <Typography variant='subtitle1' color='textSecondary'>
          City: <b>{customerInfo.customerInfo?.city}</b>
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
          Province: <b>{customerInfo.customerInfo?.province}</b>
          </Typography>
          <Typography variant='subtitle1' color='textSecondary'>
          Country: <b>{customerInfo.customerInfo?.country}</b>
          </Typography>
          </div>	       
         </div>						
        </div>						
					
      </Popover>
    </>
  );
}
