import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Select,
  TextField,
} from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import $ from "jquery";
import { useSnackbar, withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import NumericInput from "react-numeric-input";

const StockForm = ({ open, setOpen, tabRef, ...props }) => {
  const product = props.rowData;
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<any>();
  const [note, setNote] = useState<any>(product?.note);
  const [qty, setQty] = useState<any>(product?.qty);
  const [maxSelect, setMaxSelect] = useState<any>(product?.qty);

  const handleQtyChange = (value) => {
    if (value > product?.qty || value <= 0 || value === null) return;
    setQty(value);
  };

  const handleClose = () => {
    setOpen(false);
    // const searchBar = document.querySelector(`#pure_table_toolbar_id input`);
    // if (searchBar) searchBar.focus();
  };

  const handleOpen = (product) => {
    // this.setState({
    //   product: product,
    //   open: true,
    //   qty: product.qty,
    //   maxSelect: product.qty,
    // });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const source = localStorage.getItem("source");
    $.ajax({
      url: `/order_item_stages/${product?.order_item_stage_id}/stock_movement.json?source=${source}`,
      type: "POST",
      data: {
        product: product,
        qty: qty || product?.qty,
        note: note || product?.note,
      },
      dataType: "json",
      // success: function (response) {
      //   setLoading(false);
      //   handleClose();
      //   tabRef?.onQueryChange();
      //   if (response.success) {
      //     enqueueSnackbar("successfully processed!", {
      //       variant: "success",
      //     });
      //   } else {
      //     enqueueSnackbar(response.responseJSON.error, {
      //       variant: "error",
      //     });
      //   }
      success: function (response) {
        setLoading(false);
        handleClose();
        tabRef?.onQueryChange();
        props.reloadData();
        enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        setLoading(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNote({ [name]: value });
  };

  const { classes } = props;

  const actions = (
    <Button
      variant="contained"
      style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
      type="submit"
      className={classes.button}
      onClick={handleSubmit}
    >
      Submit
    </Button>
  );

  const body = (
    <Card className={classes.root}>
      <CardMedia className={classes.cover}>
        <img
          src={product?.image}
          alt={product?.name}
          style={{ height: "250px", width: "200px", objectFit: "contain" }}
        />
      </CardMedia>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" pb={5}>
            {product?.name}
          </Typography>
          <br />
          <Typography variant="subtitle1" color="textSecondary">
            QTY:{" "}
            <NumericInput
              min={1}
              max={maxSelect || product?.qty}
              value={qty || product?.qty}
              mobile
              size={4}
              onChange={(e) => {
                if (e > product?.qty) {
                  setQty(product?.qty);
                } else {
                  handleQtyChange(e);
                }
              }}
              name="qty"
            />
          </Typography>
          {product?.stock && (
            <TextField
              name="note"
              label="Comment"
              style={{ margin: 8 }}
              multiline
              fullWidth
              onChange={handleInputChange}
              margin="normal"
            />
          )}
        </CardContent>
      </div>
    </Card>
  );

  return (
    <>
      <Backdrop open={loading} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={product?.stock ? "Move item to stock" : "Move item to shipment"}
        handleClose={handleClose}
        maxHeight="60%"
        maxWidth="md"
        actions={actions}
      >
        {body}
      </Modal>
    </>
  );
};

const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 5,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    objectFit: "contain",
  },
});

export default withSnackbar(withStyles(useStyles)(StockForm));
