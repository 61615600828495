import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, MenuItem, TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import Modal from "../../shared/modal/material_dialog";
import $ from "jquery";
import { useSnackbar, withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import Alert from "@material-ui/lab/Alert";

const CreateList = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     open: false,
  //     width: null,
  //     height: null,
  //     depth: null,
  //     weight: null,
  //     shipping_method: "",
  //     shipping_methods: [],
  //     vol_weight: 0,
  //     errors: [],
  //   };
  // }

  const { enqueueSnackbar } = useSnackbar();
  const [products, setProducts] = useState<any>(props?.products);
  const [shipping_method, setShipping_Method] = useState<any>(
    props?.shipping_methods?.length === 1
      ? props?.shipping_methods[0]
      : props?.shipping_methods
  );
  const [formValue, setFormValue] = useState<any>({
    width: "",
    height: "",
    depth: "",
    weight: "weight",
  });
  // const [width, setWidth] = useState<any>("");
  // const [height, setHeight] = useState<any>("");
  // const [depth, setDepth] = useState<any>("");
  // const [weight, setWeight] = useState<any>("");
  const [vol_weight, setVol_weight] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [errors, setErrors] = useState<any>({
    width: "",
    height: "",
    weight: "",
    depth: "",
  });

  const handleClose = () => {
    props.setOpen(false);
    setFormValue({ width: "", height: "", depth: "", weight: "weight" });
  };

  // const handleOpen = (products) => {
  //   this.setState({
  //     products: products,
  //     open: true,
  //     units: this.props.units,
  //     shipping_methods: this.props.shipping_methods,
  //     width: null,
  //     height: null,
  //     depth: null,
  //     weight: null,
  //     vol_weight: 0,
  //     shipping_method: "",
  //   });
  //   if (this.props.shipping_methods.length === 1) {
  //     this.setState({ shipping_method: this.props.shipping_methods[0] });
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate(formValue)) return;
    setLoading(true);
    const payload = {
      products: props?.products,
      width: formValue.width,
      height: formValue.height,
      depth: formValue.depth,
      weight: formValue.weight,
      shipping_method:
        props?.shipping_methods?.length === 1
          ? props?.shipping_methods[0]
          : props?.shipping_methods,
    };
    const source = localStorage.getItem("source");
    const destination = localStorage.getItem("destination");
    $.ajax({
      url: `/order_item_stages/create_packing_list.json?source=${source}&location=${destination}`,
      type: "POST",
      data: payload,
      dataType: "json",
      success: function (response) {
        setLoading(false);
        props.setOpen(false);
        if (response.success) {
          enqueueSnackbar("successfully processed!", {
            variant: "success",
          });
          props.callBackCreateShipment();
        }
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    validate({ [name]: value });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("width" in fieldValues) {
      temp.width = fieldValues.width ? "" : "Required";
    }
    if ("height" in fieldValues) {
      temp.height = fieldValues.height ? "" : "Required";
    }
    if ("depth" in fieldValues) {
      temp.depth = fieldValues.depth ? "" : "Required";
    }
    if ("weight" in fieldValues) {
      temp.weight = fieldValues.weight ? "" : "Required";
    }
    if ("shipping_method" in fieldValues) {
      temp.shipping_method = fieldValues.shipping_method ? "" : "Required";
    }
    const width = fieldValues.width || formValue.width;
    const height = fieldValues.height || formValue.height;
    const depth = fieldValues.depth || formValue.depth;
    const weight = fieldValues.weight || formValue.weight;
    if (width && height && depth & weight) {
      let vol_weight;
      if (props.units[1] === "in") {
        vol_weight = ((width * height * depth) / 139).toFixed(2);
      } else {
        vol_weight = ((width * height * depth) / 6000).toFixed(2);
      }
      setVol_weight(vol_weight);
    }
    setErrors({ ...temp });
    if (fieldValues) return Object.values(temp).every((x) => x === "");
  };

  const { classes } = props;
  const actions = (
    <Button
      variant="contained"
      style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
      type="submit"
      className={classes.button}
      onClick={handleSubmit}
    >
      Submit
    </Button>
  );

  const body = (
    <Card className={classes.root} style={{ minHeight: "30vh" }}>
      <div className="container pb-5">
        {formValue.weight < vol_weight - vol_weight * 0.1 && (
          <Alert severity="error">{`The volumetric weight for this shipment is ${vol_weight}${props?.units[0]} do you wish to continue?`}</Alert>
        )}
        <p>
          You have selected {products?.length} item
          {products?.length > 1 ? "s" : ""} to create new shipment
        </p>
        <div className="row">
          <div className="col">
            <p>Dimensions:</p>
          </div>
          <div className="col">
            <InputMask
              mask="999"
              value={formValue.width}
              maskChar=" "
              onChange={handleInputChange}
            >
              {() => (
                <TextField
                  name="width"
                  label={`Width (${props?.units[1]})`}
                  className={classes.textField}
                  {...(errors.width && {
                    error: true,
                    helperText: errors.width,
                  })}
                />
              )}
            </InputMask>
          </div>
          <div className="col">
            <InputMask
              mask="999"
              value={formValue.height}
              maskChar=" "
              onChange={handleInputChange}
            >
              {() => (
                <TextField
                  name="height"
                  label={`Height (${props?.units[1]})`}
                  className={classes.textField}
                  {...(errors.height && {
                    error: true,
                    helperText: errors.height,
                  })}
                />
              )}
            </InputMask>
          </div>
          <div className="col">
            <InputMask
              mask="999"
              value={formValue.depth}
              maskChar=" "
              onChange={handleInputChange}
            >
              {() => (
                <TextField
                  name="depth"
                  label={`Depth (${props?.units[1]})`}
                  className={classes.textField}
                  {...(errors.depth && {
                    error: true,
                    helperText: errors.depth,
                  })}
                />
              )}
            </InputMask>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-3">
            <p>Weight:</p>
          </div>
          <div className="col-9">
            <InputMask
              mask="999"
              value={formValue.weight}
              maskChar=" "
              onChange={handleInputChange}
            >
              {() => (
                <TextField
                  name="weight"
                  label={props?.units[0]}
                  className={classes.textField}
                  {...(errors.weight && {
                    error: true,
                    helperText: errors.weight,
                  })}
                />
              )}
            </InputMask>
          </div>
        </div>
        {shipping_method?.length > 1 && (
          <div className="row pt-3">
            <div className="col-3"></div>
            <div className="col-9">
              <TextField
                label="Shipping Method"
                select
                fullWidth
                value={shipping_method || ""}
                {...(errors.shipping_method && {
                  error: true,
                  helperText: errors.shipping_method,
                })}
                onChange={(event) => setShipping_Method(event.target.value)}
              >
                {shipping_method.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        )}
      </div>
    </Card>
  );

  return (
    <>
      <Backdrop open={loading} />
      <Modal
        disableBackdropClick
        isOpen={props?.open}
        title="New Shipment"
        handleClose={handleClose}
        maxHeight="400%"
        maxWidth="sm"
        actions={actions}
      >
        {body}
      </Modal>
    </>
  );
};

const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 5,
  },
  textField: {
    margin: theme.spacing(1),
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    objectFit: "contain",
  },
});

export default withSnackbar(withStyles(useStyles)(CreateList));
