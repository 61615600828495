import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MenuItem, Badge } from "@material-ui/core";
import Table from "../shared/tables/material_table";
import Modal from "../shared/modal/material_dialog";
import Select from "@material-ui/core/Select";
import ShowItem from "../order_items/show";
import pureIcons from "../shared/pure_icons";

const FindItem = forwardRef(({}: any, ref: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [stages, setStages] = useState<any>([]);

  const _ShowItemRef = useRef<any>(null);
  const _tableRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const gerSourceDestData = (input) => {
    if (input === "source") {
      return localStorage.getItem("source") || "";
    } else if (input === "destination") {
      return localStorage.getItem("destination") || "";
    }
  };

  const columns = [
    {
      title: "",
      field: "image_url",
      sorting: false,
      filtering: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "60px", width: "60px", objectFit: "contain" }}
              alt={row.name}
              src={row.image_url}
            ></img>
          </Badge>
        </div>
      ),
    },
    {
      title: "Product Name",
      field: "item_name",
      sorting: false,
      cellStyle: { whiteSpace: "normal", wordWrap: "break-word", width: "15%" },
    },
    {
      title: "QTY",
      field: "quantity",
      sorting: false,
      cellStyle: { width: "2%" },
      filtering: false,
    },
    { title: "Vendor", field: "vendor", sorting: false },
    { title: "Order Number", field: "order_number", sorting: false },
    {
      title: "Order Date",
      field: "order_date",
      sorting: false,
      filtering: false,
    },
    { title: "code", field: "code", sorting: false },
    { title: "Source", field: "source", sorting: false },
    { title: "Market", field: "market", sorting: false },
  ];
  const body = (
    <div className="bg-white rounded">
      <ShowItem ref={_ShowItemRef} />
      {/* reloadData={reloadData} */}
      <Table
        options={{
          toolbar: false,
          filtering: true,
          pageSize: 5,
          pageSizeOptions: [5, 25, 100],
          debounceInterval: 1000,
        }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          {
            icon: pureIcons.ImInfo,
            tooltip: "Show",
            onClick: (event, rowData) =>
              _ShowItemRef.current.handleOpen(
                rowData.order_item_id,
                gerSourceDestData("source") || rowData.source
              ),
            position: "row",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            let url = "/core_items.json?";
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + encodeURIComponent(JSON.stringify(filters));
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    throw new Error('You need to sign in or sign up before continuing.');
                  }
                }
                return response.json();
              })
              .then((result) => {
                resolve({
                  data: result.core_items,
                  // stages: result.stages,
                  page: result.page - 1,
                  totalCount: result.total,
                });
                window.breadcrumb.addBreadcrumb(result.breadcrumb);
              })
              .catch((error) => {
                if (error.message === 'You need to sign in or sign up before continuing.') {
                  window.location.href = '/'
                } else {
                  console.error('Error:', error);
                }
              });
          })
        }
      />
    </div>
  );

  return (
    <Modal
      isOpen={open}
      title="Find Item"
      handleClose={handleClose}
      minHeight="40vh"
      maxHeight="90%"
      maxWidth="xl"
    >
      {body}
    </Modal>
  );
});

export default FindItem;
