import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import Backdrop from "../shared/backdrop";
import $ from "jquery";
import pureIcons from "../shared/pure_icons";

const ImageUploadForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const barcodeId: any = searchParams.get("barcode");
  const [loader, setLoader] = useState<boolean>(false);
  const [ImgList, setImgList] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [uniqueBarcode, setUniqueBarcode] = useState<any>(false);

  const onSetImage = async (event) => {
    const files = Array.from(event.target.files);
    setImgList((prevImg) => [...prevImg, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImgList((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const onUploadImage = () => {
    setLoading(true);
    const ImageData = ImgList.map((value) => {
      return {
        image: value,
      };
    });
    const data = new FormData();
    data.append("barcode", barcodeId);
    ImageData.forEach((image, index) => {
      data.append(`images[][image]`, image.image);
    });

    $.ajax({
      url: `/dummy_packages/upload_image`,
      type: "POST",
      data: data,
      dataType: "json",
      // enctype: "multipart/form-data",
      // cache: false,
      processData: false,
      contentType: false,
      success: function (response) {
        console.log(response);
        enqueueSnackbar("Image uploaded successfully", {
          variant: "success",
        });
        setUniqueBarcode(true);
        setLoading(false);
      },
      error: (error) => {
        setLoading(false);
        enqueueSnackbar(error.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const imageUploadBody = (
    <>
      <Backdrop open={loading} />
      {/* <Box
        style={{
          marginTop: "20px",
        }}
      >
        <Typography style={{ position: "absolute" }}>Barcode:</Typography>
        <div
          onClick={() => onOpenImageSection()}
          style={{ cursor: "pointer", marginLeft: "20%" }}
        >
          <Barcode value={uniqueBarcode} height={30} width={1.04} />
        </div>
      </Box> */}

      <Box
        style={{
          marginTop: "20px",
        }}
      >
        <div>
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={(event) => onSetImage(event)}
          />
          <div style={{ marginTop: "20px", position: "relative" }}>
            {ImgList.map((image, index) => (
              <>
                <img
                  key={index}
                  src={URL.createObjectURL(image)}
                  alt={`Image ${index}`}
                  height="90px"
                  width="100px"
                />
                <button
                  style={{
                    padding: "0",
                    border: "0",
                    position: "absolute",
                  }}
                  onClick={() => handleRemoveImage(index)}
                >
                  <pureIcons.Delete />
                </button>
              </>
            ))}
          </div>
        </div>
      </Box>
    </>
  );

  return (
    <>
      {/* {uniqueBarcode ? ( */}
      <>
        <Backdrop open={loader} />
        <div style={{ margin: "2rem 0rem 0rem 6rem" }}>
          {" "}
          {imageUploadBody}
          <Button
            type="button"
            style={{
              backgroundColor: "#ec9a53",
              color: "#ebeef0",
              textTransform: "none",
              marginTop: "2rem",
            }}
            onClick={onUploadImage}
          >
            Upload
          </Button>{" "}
        </div>
      </>
      {/*    ) : (
    //     <div>Image Uploaded Successfully</div>
    //    */}
    </>
  );
};

export default ImageUploadForm;
