import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Received from "./received";

const ScrollableTabsButtonAuto = ({ orderState }) => {
  const useStyles = makeStyles((theme: any) => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  function TabPanel(props: any) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
      >
        {value === index && children}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  return (
    <div className={classes.root} id="pure-scrollable-tab">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="all" {...a11yProps(0)} />
        <Tab label="automated" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Received automated={false} orderState={orderState} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Received automated={true} orderState={orderState} />
      </TabPanel>
    </div>
  );
};

export default ScrollableTabsButtonAuto;
