import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { GrUserFemale, GrUserManager } from 'react-icons/gr';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: '6px 16px',
	},
	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
}));

export default function CustomizedTimeline({ notes }: any) {
	const classes = useStyles();
	const messagesEndRef = useRef<any>(null)
	const scrollToBottom = () => {
		// messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
	}
	useEffect(scrollToBottom, [notes]);

	const getModules = () => {
		return <>
			{notes?.map((note: any, i: number) => {
				return (
					<TimelineItem className='timeline-scroll-to-bottom' key={i}>
						<TimelineOppositeContent>
							<Typography variant='caption' display='block' gutterBottom>
								{note.date}
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineDot>
								{note.gender === 'Male' ? <GrUserManager /> : <GrUserFemale />}
							</TimelineDot>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>
							<Paper elevation={3} className={classes.paper}>
								<Typography variant='caption' color='textSecondary'>
									{note.user}
								</Typography>
								<Typography>{note.message}</Typography>
							</Paper>
						</TimelineContent>
					</TimelineItem>)
					;
				<div ref={messagesEndRef} />
			})}
		</>
	};
	return (
		<Timeline align='alternate'>
			{getModules()}
		</Timeline>
	);
}
