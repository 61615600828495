import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, TextField } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import { useSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import Table from "../../shared/tables/material_table";
import $ from "jquery";
import NumericInput from "react-numeric-input";
import ReactToPrint from "react-to-print";
import Invoice2 from "./_invoice2";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {},
  button: {},
}));

const PackingListForm2 = forwardRef(({source, location}: any, ref: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [errors, setErrors] = useState<any>({});
  const [boxes, setBoxes] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [total, setTotal] = useState<any>();
  const [total_weight, setTotalWeight] = useState<number>(0.0);
  const [factor, setFactor] = useState<number>(1);
  const [working, setWorking] = useState<boolean>(false);
  const [invoice_data, setInvoiceData] = useState<any>();
  const [signature, setSignature] = useState<any>();
  const [file_path, setFilePath] = useState<any>();
  const [category_counts, setCategoryCounts] = useState<any>();
  const [logo, setLogo] = useState<any>();

  useImperativeHandle(ref, () => ({
    handleOpen(data) {
      setWorking(true);
      setFactor(1);
      $.ajax({
        url: `/order_item_stages/prepare_detail_invoice.json?group_ids=${data}&source=${source}&location=${location}`,
        type: "GET",
        dataType: "json",
        success: function (response) {
          setProducts(response.products);
          setWorking(false);
          setBoxes(response.boxes);
          setFilePath(null);
          setInvoiceData(response.invoice_data);
          setOpen(true);
          setSignature(response.signature);
          setCategoryCounts(response.category_counts);
          var valuesList = {};
          response.boxes.map(
            (box) =>
              (valuesList = {
                ...valuesList,
                [`w_${box.name}`]: box.weight,
                [`d_${box.name}`]: box.dimensions,
              })
          );
          setValues(valuesList);
          setTotalWeight(response.total_weight);
        },
        error: function (response) {
          enqueueSnackbar(response.responseJSON.error, { variant: "error" });
          setWorking(false);
        },
      });
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setErrors({});
    setPage(1);
    setProducts([]);
    setBoxes([]);
    setValues({});
  };

  const handleInputChange = (value) => {
    setFactor(value);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    temp.serverErrors = "";
    boxes.map((box) => {
      if (`w_${box.name}` in fieldValues) {
        temp[`w_${box.name}`] = fieldValues[`w_${box.name}`] ? "" : "Required";
      }
      if (`d_${box.name}` in fieldValues) {
        temp[`d_${box.name}`] = fieldValues[`d_${box.name}`] ? "" : "Required";
      }
    });
    setErrors({ ...temp });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleBoxInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validate({ [name]: value });
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 700,
      },
    },
    {
      title: "Box No.",
      field: "group_id",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 200,
      },
    },
    { title: "QTY", field: "qty", sorting: false },
    { title: `Unit Price($)`, field: "price" },
    {
      title: `Total ($)`,
      field: "",
      render: (rowData) => (rowData.qty * rowData.price * factor).toFixed(2),
      editable: "never",
    },
  ];

  const body =
    page === 1 ? (
      <Table
        title=""
        options={{
          toolbar: true,
          paging: false,
          filtering: false,
          sorting: false,
          debounceInterval: 1000,
          addRowPosition: "first",
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                setProducts([...products, newData]);
                resolve();
              }, 100);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...products];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setProducts([...dataUpdate]);
                resolve();
              }, 100);
            }),
          onRowDelete: (oldData) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...products];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setProducts([...dataDelete]);
                resolve();
              }, 100);
            }),
        }}
        columns={columns}
        data={products}
      />
    ) : (
      <Card className={classes.root} style={{ minHeight: "30vh" }}>
        <div className="container pb-5">
          {boxes.map((box) => (
            <div className="row pt-2">
              <div className="col" />
              <div className="col">
                <TextField
                  className={classes.textField}
                  fullWidth
                  name={`w_${box.name}`}
                  value={values[`w_${box.name}`]}
                  label={box.name + " Weight"}
                  {...(errors[`w_${box.name}`] && {
                    error: true,
                    helperText: errors[`w_${box.name}`],
                  })}
                  onChange={handleBoxInputChange}
                />
              </div>
              <div className="col">
                <TextField
                  className={classes.textField}
                  name={`d_${box.name}`}
                  fullWidth
                  value={values[`d_${box.name}`]}
                  label={box.name + " Dimensions"}
                  {...(errors[`d_${box.name}`] && {
                    error: true,
                    helperText: errors[`d_${box.name}`],
                  })}
                  onChange={handleBoxInputChange}
                />
              </div>
              <div className="col" />
            </div>
          ))}
        </div>
      </Card>
    );

  const summary = (
    <div className="container">
      <div className="row ms-4 pt-4">
        <div className="col-2">
          <NumericInput
            min={0}
            max={1}
            step={0.01}
            value={factor}
            mobile
            size={6}
            onChange={handleInputChange}
            name="factor"
            disabled={page === 2}
          />
        </div>
        <div className="col-2">
          <h6>
            Value: ${" "}
            {products
              .reduce((a, v) => (a = a + v.qty * v.price * factor), 0)
              .toFixed(2)}
          </h6>
        </div>
        <div className="col-8">
          <h6>Weight: {total_weight}</h6>
        </div>
      </div>
    </div>
  );

  const actions =
    page === 1 ? (
      <Button
        variant="contained"
        type="submit"
        className={classes.button}
        disabled={working}
        onClick={() => setPage(2)}
      >
        Next
      </Button>
    ) : (
      <PrintInvoice
        products={products}
        values={values}
        factor={factor}
        invoice_data={invoice_data}
        logo={logo}
        signature={signature}
      />
    );

  return (
    <>
      <Backdrop open={working} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={"Prepare Invoice"}
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
        summary={summary}
        actions={actions}
      >
        {body}
      </Modal>
    </>
  );
});

export default PackingListForm2;

const PrintInvoice = ({
  invoice_data,
  signature,
  factor,
  products,
  logo,
}: any) => {
  const componentRef = useRef<any>(null);
  document.title = invoice_data.file_name;
  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <Button type="submit" variant="outlined" color="primary">
            print invoice
          </Button>
        )}
        content={() => componentRef.current}
      />
      <Invoice2
        ref={componentRef}
        invoice_data={invoice_data}
        products={products}
        factor={factor}
        logo={logo}
        signature={signature}
      />
    </div>
  );
};
