// import React from 'react';
import React, { useState, useEffect, useRef } from "react";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { Paper } from "@material-ui/core";
import pureIcons from "../pure_icons";

const defaultOptions = {
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  rowStyle: { whiteSpace: "normal", wordWrap: "break-word" },
  headerStyle: {
    whiteSpace: "nowrap",
    color: "#04423dd7",
    padding: "0.5em",
    backgroundColor: "#eff2f8",
  },
  cellStyle: { padding: "0.5em" },
  maxBodyWidth: "100px",
  sorting: true,
  search: true,
  paging: true,
  toolbar: true,
  filtering: true,
  exportButton: false,
  searchAutoFocus: false,
};

const Table = ({
  title = "",
  options,
  columns,
  data,
  actions,
  editable,
  localization,
  cellEditable,
  tableRef,
  detailPanel,
  onRowClick,
}) => {
  const tableOptions = { ...defaultOptions, ...options };
  const [materialColumn] = useState(columns);
  const [pageSize, setPageSize] = useState(
    parseInt(localStorage.getItem("pageSize")) || tableOptions.pageSize
  );

  useEffect(() => {
    localStorage.setItem("pageSize", pageSize);
  }, [pageSize]);

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  let tableID;
  if (!tableOptions.toolbar && !tableOptions.paging) {
    tableID = "pure-material-table-no-header-and-footer";
  } else if (!tableOptions.paging) {
    tableID = "pure-material-table-no-footer";
  } else if (!tableOptions.toolbar) {
    tableID = "pure-material-table-no-header";
  } else {
    tableID = "pure-material-table";
  }
  const TOOLBAR_ID = "pure_table_toolbar_id";
  useEffect(() => {
    const searchBar = document.querySelector(`#${TOOLBAR_ID} input`);
    if (!searchBar || !tableOptions.focus) return;
    searchBar.focus();
  });

  return (
    <MaterialTable
      title={title}
      editable={editable}
      localization={localization}
      tableRef={tableRef}
      icons={pureIcons}
      options={{ ...tableOptions, pageSize, emptyRowsWhenPaging: false }}
      columns={materialColumn}
      actions={actions}
      cellEditable={cellEditable}
      data={data}
      components={{
        Container: (props) => <Paper className={tableID} {...props} />,
        Toolbar: (props) => {
          return (
            <div id={TOOLBAR_ID}>
              <MTableToolbar {...props} />
            </div>
          );
        },
      }}
      detailPanel={detailPanel}
      onRowClick={onRowClick}
      onChangeRowsPerPage={handlePageSizeChange}
    />
  );
};
export default Table;
