import React, { useRef, useState } from "react";
import Table from "../../shared/tables/material_table";
import { useSnackbar } from "notistack";
import { FiRefreshCw, FiPackage } from "react-icons/fi";
import Backdrop from "../../shared/backdrop";
import { ImInfo } from "react-icons/im";
import StockForm from "../stocked/_stock_form";
import { BsBookshelf, BsChatDots, BsPlusLg } from "react-icons/bs";
import NoteForm from "../../notes/_note_form";
import { Link, Badge, IconButton, Switch, styled } from "@material-ui/core";
import Barcode from "../../order_items/_barcode";
import AddToList from "./_add_to_packing_list_form";
import ShowItem from "../../order_items/show";
import CreateShipment from "./_create_shipment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import pureIcons from "../../shared/pure_icons";
import TextField from "@material-ui/core/TextField";
import currentData from "./current-packing.json";
import { useHistory } from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [unit, setUnit] = useState<any>();
  const [shipping_methods, setShipping_Methods] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const tableRef = useRef<any>();
  const _stockFormRef = useRef<any>();
  const _noteFormRef = useRef<any>();
  const _ShowItemRef = useRef<any>();
  const hub_operators = useRef<any>([]);

  const [open, setOpen] = useState<any>();
  const [openAddShipment, setOpenAddShipment] = useState<any>(false);
  const [openCreateShipment, setOpenCreateShipment] = useState<any>(false);
  const [rowData, setRowData] = useState<any>();
  const _AddToListRef = useRef<any>();
  const _CreateShipmentRef = useRef<any>();
  const [tabRef, setTabRef] = useState<any>();
  const [orderState, setOrderState] = useState<boolean>(true);

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const columns = [
    {
      title: "",
      field: "image",
      sorting: false,
      filtering: false,
      cellStyle: { width: "5%" },
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "60px", width: "60px", objectFit: "contain" }}
              alt={row.name}
              src={row.image}
            ></img>
          </Badge>
        </div>
      ),
    },
    {
      title: "Product Name",
      field: "name",
      sorting: false,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        width: "30%",
      },
      filtering: false,
      render: (rowData) => (
        <Link href={rowData.url} target="_blank">
          {rowData.name_short}
        </Link>
      ),
    },
    {
      title: "QTY",
      field: "qty",
      sorting: false,
      cellStyle: { width: "2%" },
      filtering: false,
    },
    {
      title: "Tracking",
      field: "tracking",
      sorting: false,
      filtering: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 300,
      },
    },
    { title: "Vendor", field: "brand", filtering: false, sorting: false },
    {
      title: "Code",
      field: "redeem_code",
      sorting: false,
      filtering: false,
      editable: "never",
    },
    { title: "Time moved", field: "updated_at", filtering: false },
    {
      title: "Processed By",
      field: "who_did_it",
      sorting: false,
      editable: "never",
      filterComponent: ({ columnDef, onFilterChanged }: any) => (
        <Autocomplete
          style={{ width: "150px" }}
          multiple
          id="checkboxes-tags-demo"
          options={hub_operators.current}
          disableCloseOnSelect
          limitTags={1}
          onChange={(e, newValue) => {
            onFilterChanged(columnDef.tableData.id, newValue);
          }}
          getOptionLabel={(option: any) => option}
          renderOption={(option: any, { selected }: any) => (
            <React.Fragment>
              <Checkbox
                icon={<pureIcons.CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<pureIcons.CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      title: "Code",
      field: "redeem_code",
      sorting: false,
      filtering: false,
      cellStyle: { width: "2%" },
      render: (rowData) => (
        <IconButton aria-label="Barcode">
          <Barcode code={rowData.redeem_code} />
        </IconButton>
      ),
    },
  ];

  const IOSSwitch = styled((props: any) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 80,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(53.5px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#f6b67f" : "#d9d8d8",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "dark" ? "#40C071" : "green",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const switchAction = () => {
    setOrderState((prev) => !prev);
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  return (
    <div className="bg-white rounded">
      <Backdrop open={loading} />
      <AddToList
        ref={_AddToListRef}
        callBackAddToList={reloadData}
        shipping_methods={shipping_methods}
        open={openAddShipment}
        setOpen={setOpenAddShipment}
        products={rowData}
        orderState={!orderState}
      />
      <CreateShipment
        ref={_CreateShipmentRef}
        callBackCreateShipment={reloadData}
        units={unit}
        shipping_methods={shipping_methods}
        open={openCreateShipment}
        setOpen={setOpenCreateShipment}
        products={rowData}
      />
      <NoteForm ref={_noteFormRef} newNoteChild={reloadData} />
      <ShowItem ref={_ShowItemRef} reloadData={reloadData} />
      <StockForm
        stock={true}
        ref={_stockFormRef}
        open={open}
        setOpen={setOpen}
        rowData={rowData}
        tabRef={tabRef}
      />
      <Table
        title={
          <>
            <span
              style={{
                marginRight: "2rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              Current Packing List
            </span>
            <span onClick={() => switchAction()}>
              <span
                style={{
                  position: "absolute",
                  zIndex: 1,
                  color: orderState ? "#315e5a" : "white",
                  fontSize: "1rem",
                  marginLeft: orderState ? "1rem" : "2rem",
                }}
              >
                {orderState ? "Sea" : "Sea"}
              </span>
              <IOSSwitch sx={{ m: 1 }} checked={orderState} />
            </span>
          </>
        }
        options={{
          toolbar: true,
          paging: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          selection: true,
          searchAutoFocus: true,
        }}
        columns={columns}
        tableRef={tableRef}
        actions={[
          // {
          //   icon: () => (
          //     <>
          //       <span
          //         style={{
          //           position: "absolute",
          //           zIndex: 1,
          //           color: orderState ? "#315e5a" : "white",
          //           fontSize: "1rem",
          //           marginRight: orderState ? "1rem" : "-1rem",
          //         }}
          //       >
          //         {orderState ? "Sea" : "Sea"}
          //       </span>
          //       <IOSSwitch sx={{ m: 1 }} defaultChecked checked={orderState} />
          //     </>
          //   ),
          //   onClick: () => {
          //     switchAction();
          //   },
          //   isFreeAction: true,
          //   tooltip: "Shipping Options",
          // },
          {
            icon: () => <FiRefreshCw />,
            onClick: () => {
              reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          {
            tooltip: "Create Shipment",
            icon: () => <FiPackage />,
            onClick: (event, rowData) => {
              setOpenCreateShipment(true), setRowData(rowData);
            },
          },
          {
            icon: () => <BsBookshelf />,
            onClick: (event, rowData) => {
              setRowData(rowData),
                setOpen(true),
                _stockFormRef.current.handleOpen(rowData);
            },
            tooltip: <span>Move it to stock</span>,
            position: "row",
          },
          {
            icon: () => <BsPlusLg />,
            tooltip: "Add to Shipment",
            onClick: (event, rowData) => {
              setOpenAddShipment(true);
              setRowData(rowData);
            },
          },
          {
            icon: () => <ImInfo />,
            tooltip: <span>Show</span>,
            onClick: (event, rowData) => {
              const source = localStorage.getItem("source");
              _ShowItemRef.current.handleOpen(rowData.order_item_id, source);
            },
            position: "row",
          },
          {
            icon: () => <BsChatDots />,
            tooltip: "Notes",
            onClick: (event, rowData) => {
              const source = localStorage.getItem("source");
              _noteFormRef.current.handleOpen(
                rowData.order_item_stage_id,
                source
              );
            },
            position: "row",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            const source = localStorage.getItem("source");
            const destination = localStorage.getItem("destination");
            let url = `/order_item_stages/warehouse.json?source=${source}&location=${destination}&sea_shipping=${!orderState}`;
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy.field;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    throw new Error(
                      "You need to sign in or sign up before continuing."
                    );
                  }
                }
                return response.json();
              })
              .then((result) => {
                if (result?.error) {
                  enqueueSnackbar(
                    "Something went wrong, you are redirected to home page",
                    {
                      variant: "error",
                    }
                  );

                  setTimeout(() => {
                    history.push("/");
                  }, 500);

                  return;
                }
                resolve({
                  data: result.products,
                });
                setUnit(result.units),
                  result?.process_by_users
                    ? (hub_operators.current = result?.process_by_users)
                    : (hub_operators.current = []),
                  setShipping_Methods(result.shipping_methods),
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
              })
              .catch((error) => {
                if (
                  error.message ===
                  "You need to sign in or sign up before continuing."
                ) {
                  window.location.href = "/";
                } else {
                  console.error("Error:", error);
                }
              });
          })
        }
      />
    </div>
  );
};

export default Index;
