import React, { useRef, useState } from "react";
import Table from "../../shared/tables/material_table";
import PurchaseForm from "./_purchase_form";
import {
  Badge,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
} from "@material-ui/core";
import ShowOrder from "../_show";
import OrdersSummary from "./_orders_summary";
import AutoControlPanel from "./_auto_control_panel";
import pureIcons from "../../shared/pure_icons";
import { MenuItem } from "react-pro-sidebar";

const Index = ({ automated, orderState }: any) => {
  const [orders, setOrders] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [order_total, setOrderTotal] = useState<any>(null);
  const [orderStateValue, setOrderStateValue] = useState<any>(false);
  const [selectedRoles, setSelectedRoles] = useState<any>("");
  const selectedValue = useRef<any>("Others");

  const _tableRef = useRef<any>();
  const _PurchaseFormRef = useRef<any>();
  const _ShowOrderRef = useRef<any>();
  const _AutoControlPanelRef = useRef<any>();
  const _OrdersSummaryRef = useRef<any>();

  const reloadData = () => {
    _tableRef.current.onQueryChange();
  };

  const handleRoleFilterChange = (val) => {
    setSelectedRoles(val);
    selectedValue.current = val;
    reloadData();
  };

  const columns = [
    {
      title: "Order Number",
      field: "order_number",
      sorting: false,
      filtering: false,
      editable: "never",
      render: (rowData) => (
        <Badge
          badgeContent={rowData.new_customer ? "new" : null}
          color="secondary"
        >
          {rowData.order_number}
        </Badge>
      ),
    },
    { title: "Received at", field: "created_at", filtering: false },
    { title: "Vendors", field: "vendors", sorting: false, filtering: false },
    {
      title: "Order Total",
      field: "order_total",
      sorting: false,
      filtering: false,
    },
    {
      title: "Products Count",
      field: "number_of_items",
      sorting: false,
      filtering: false,
    },
    {
      title: "Shipping Method",
      field: "shipping_option",
      sorting: false,
      editable: "never",
      filtering: true,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        width: "10%",
      },
      lookup: { Sea: "Sea", Others: "Others" },
      filterComponent: () => (
        <FormControl component="fieldset" style={{ width: "90%" }}>
          <Select
            value={selectedRoles || selectedValue.current}
            onChange={(e) => {
              handleRoleFilterChange(e.target.value),
                (selectedValue.current = e.target.value);
            }}
          >
            <MenuItem value="Sea" style={{ padding: "0rem 1rem" }}>
              <FormControlLabel
                value="Sea"
                control={
                  <Radio
                    checked={selectedRoles || selectedValue.current == "Sea"}
                  />
                }
                label="Sea"
              />
            </MenuItem>
            <MenuItem value="Others" style={{ padding: "0rem 1rem" }}>
              <FormControlLabel
                value="Others"
                control={
                  <Radio
                    checked={selectedRoles || selectedValue.current == "Others"}
                  />
                }
                label="Others"
              />
            </MenuItem>
          </Select>
        </FormControl>
      ),
      render: (rowData) => <span>{rowData?.shipping_option}</span>,
    },
    { title: "id", field: "id", hidden: true },
    { title: "market_source", field: "market_source", hidden: true },
    { title: "group_id", field: "group_id", hidden: true },
    { title: "new_customer", field: "new_customer", hidden: true },
  ];

  const detailsColumns = [
    { title: "Vendor", field: "vendor", sorting: false },
    { title: "Items qty", field: "products_count", sorting: false },
    { title: "Value", field: "order_total", sorting: false },
  ];
  return (
    <div className="bg-white rounded">
      <ShowOrder ref={_ShowOrderRef} />
      <AutoControlPanel ref={_AutoControlPanelRef} />
      <OrdersSummary ref={_OrdersSummaryRef} />
      <PurchaseForm ref={_PurchaseFormRef} reloadData={reloadData} />
      <Table
        title={"Received Orders"}
        options={{
          filtering: true,
          sorting: true,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
        }}
        localization={{ toolbar: { searchPlaceholder: "Order or vendor" } }}
        columns={columns}
        tableRef={_tableRef}
        actions={[
          {
            icon: () => <pureIcons.FiRefreshCw />,
            onClick: () => {
              reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          {
            icon: () => <pureIcons.Traffic />,
            onClick: () => {
              _AutoControlPanelRef.current.handleOpen();
            },
            isFreeAction: true,
            tooltip: "Auto control",
          },
          {
            icon: () => <pureIcons.Summarize />,
            onClick: () => _OrdersSummaryRef.current.handleOpen(),
            isFreeAction: true,
            tooltip: "Orders Summary",
          },
          (rowData) => ({
            icon: () => <pureIcons.ImInfo />,
            tooltip: <span>Show order details</span>,
            onClick: () =>
              _ShowOrderRef.current.handleOpen(
                rowData.order_number,
                rowData.source,
                rowData.new_customer
              ),
            position: "row",
          }),
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            selectedValue.current == "Sea"
              ? setOrderStateValue(true)
              : setOrderStateValue(false);
            const destination = localStorage.getItem("destination");
            const source = localStorage.getItem("source");
            let url = `/stages/received.json?location=${destination}&automated=${automated}&sea_shipping=${
              selectedValue.current == "Sea" ? true : false
            }&source=${source}&`;
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy.field;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    throw new Error(
                      "You need to sign in or sign up before continuing."
                    );
                  }
                }
                return response.json();
              })
              .then((result) => {
                resolve({
                  data: result.orders.flat(),
                  page: result.page - 1,
                  totalCount: result.total,
                });
                window.breadcrumb.addBreadcrumb(result.breadcrumb);
              })
              .catch((error) => {
                if (
                  error.message ===
                  "You need to sign in or sign up before continuing."
                ) {
                  window.location.href = "/";
                } else {
                  console.error("Error:", error);
                }
              });
          })
        }
        detailPanel={(parentRowData) => {
          return (
            <Table
              options={{
                filtering: false,
                toolbar: false,
                paging: false,
                pageSize: parentRowData.vendors_count,
              }}
              columns={detailsColumns}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `/stages/${parentRowData?.rowData?.order_number}/received_details.json?sea_shipping=${orderStateValue}`;
                  fetch(url)
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.order,
                        page: result.page - 1,
                        totalCount: result.total,
                      });
                    });
                })
              }
              actions={[
                (rowData) => ({
                  onClick: () => {
                    _PurchaseFormRef.current.handleOpen(
                      rowData.order_number,
                      rowData.vendor,
                      parentRowData.rowData?.source,
                      parentRowData.rowData?.market_source,
                      parentRowData.rowData?.group_id,
                      parentRowData.rowData?.shipping_option,
                      parentRowData.rowData?.new_customer,
                      orderStateValue
                    );
                  },
                  icon: () => <pureIcons.AiOutlineShoppingCart />,
                  tooltip: <span>Shop {rowData.vendor}</span>,
                  position: "row",
                }),
              ]}
            />
          );
        }}
      />
    </div>
  );
};

export default Index;
