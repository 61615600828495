import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FiShoppingCart,
  FiChevronsLeft,
  FiChevronsRight,
  FiSearch,
  FiPackage,
} from "react-icons/fi";
import {
  FaClipboardList,
  FaShippingFast,
  FaWarehouse,
  FaFileInvoiceDollar,
  FaBusinessTime,
  FaRegThumbsUp,
} from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
import {
  GiBoxUnpacking,
  GiStorkDelivery,
  GiCommercialAirplane,
  GiCardPickup,
} from "react-icons/gi";
import { BsHandThumbsUp, BsBookshelf } from "react-icons/bs";
import { IoBarChartSharp } from "react-icons/io5";
import {
  GrDocumentCsv,
  GrSettingsOption,
  GrUserSettings,
  GrHostMaintenance,
} from "react-icons/gr";
// import FindItem from "../marketplace_products/_index"
import FindItem from "../marketplace_products/_index";
import { ImTree } from "react-icons/im";
import InfoIcon from "@material-ui/icons/Info";
import SourceDestinationSelection from "../shared/_sourceDestinationSelection";
import Flags from "country-flag-icons/react/3x2";
import Global_Flag from "../../../assets/images/locations/global.png";
import Dummy_Package from "../../components/dummy_package/index";
//import { Typography } from "@material-ui/core";

const Sidebar = (props) => {
  const [sidebar, setSidebar] = useState<any>([]);
  const [collapsed, setCollapsed] = useState<any>(
    localStorage.getItem("collapsed") === "true"
  );
  const [loading, setLoading] = useState<any>();

  const _ShowUserRef = React.createRef<any>();
  const _FindItemRef = React.createRef<any>();
  const _ShowOrder = React.createRef<any>();
  const _OpenSourceDestinationModel = React.createRef<any>();
  // const _DummyPackage = React.createRef<any>();

  useEffect(() => {
    const { page_id } = props;
    let url = `/sidebar_content.json?`;
    if (page_id) url = url + `page_id=${page_id}`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setSidebar(result.sidebar);
        localStorage.setItem("sidebarLength", result.sidebar.length);
        setLoading(false);
      });
  }, []);

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
    document.getElementById("content").setAttribute("collapsed", !collapsed);
    localStorage.setItem("collapsed", !collapsed);
  };

  const KeysToComponentMap = {
    FiShoppingCart: FiShoppingCart,
    FaClipboardList: FaClipboardList,
    FiSearch: FiSearch,
    AiOutlineFileSearch: AiOutlineFileSearch,
    GiBoxUnpacking: GiBoxUnpacking,
    FaShippingFast: FaShippingFast,
    FaWarehouse: FaWarehouse,
    BsHandThumbsUp: BsHandThumbsUp,
    BsBookshelf: BsBookshelf,
    FiPackage: FiPackage,
    IoBarChartSharp: IoBarChartSharp,
    GrDocumentCsv: GrDocumentCsv,
    ImTree: ImTree,
    GrSettingsOption: GrSettingsOption,
    GrUserSettings: GrUserSettings,
    FaFileInvoiceDollar: FaFileInvoiceDollar,
    FaBusinessTime: FaBusinessTime,
    GiStorkDelivery: GiStorkDelivery,
    FaRegThumbsUp: FaRegThumbsUp,
    GiCommercialAirplane: GiCommercialAirplane,
    GiCardPickup: GiCardPickup,
    GrHostMaintenance: GrHostMaintenance,
  };

  const arrow = (
    <IconButton size="small" component="span" onClick={handleToggleSidebar}>
      {collapsed ? <FiChevronsRight /> : <FiChevronsLeft />}
    </IconButton>
  );

  const menu_items = sidebar.map((m) =>
    m.menu ? (
      <MenuItem
        title={m.menu.name}
        icon={React.createElement(KeysToComponentMap[m.menu.icon])}
        key={m.menu.name}
      >
        {m.menu.name} <Link to={m.menu.link} />{" "}
      </MenuItem>
    ) : (
      <SubMenu
        title={m.sub_menu.name}
        icon={React.createElement(KeysToComponentMap[m.sub_menu.icon])}
        key={m.sub_menu.name}
        defaultOpen={m.sub_menu.open}
      >
        {m.sub_menu.sub_menu_items.map((mm) =>
          mm.sub_menu ? (
            <SubMenu
              title={mm.sub_menu.name}
              prefix={React.createElement(KeysToComponentMap[mm.sub_menu.icon])}
              key={mm.sub_menu.name}
              defaultOpen={mm.sub_menu.open}
            >
              {mm.sub_menu.sub_menu_items.map((mmm) => (
                <MenuItem
                  prefix={React.createElement(KeysToComponentMap[mmm.icon])}
                  key={mmm.name}
                >
                  {mmm.name} <Link to={mmm.link} />
                </MenuItem>
              ))}
            </SubMenu>
          ) : (
            <MenuItem
              prefix={React.createElement(KeysToComponentMap[mm.icon])}
              key={mm.name}
            >
              {mm.name} <Link to={mm.link} />
            </MenuItem>
          )
        )}
      </SubMenu>
    )
  );

  const FlagsArray = (flagNationCode) => {
    const Flag = Flags[flagNationCode];

    return (
      <Flag className="flag-style" style={{ width: "30px", height: "30px" }} />
    );
  };

  const content = new Uint8Array(Global_Flag);
  const global_image = URL.createObjectURL(
    new Blob([content.buffer], { type: "image/png" } /* (1) */)
  );

  const getSource = () => {
    const source = localStorage.getItem("source");
    if (!source) return "";

    if (source === "ksa") {
      return "SA";
      // } else if (source === "ae") {
      //   return "AE";
    } else {
      return source.toUpperCase();
    }
  };

  const selectedSource =
    localStorage.getItem("destination")?.toUpperCase() || "";
  const selectedDestination = getSource();

  const source_flag =
    selectedSource !== "" ? (
      selectedSource === "GLOBAL" ? (
        <img src={global_image} />
      ) : (
        FlagsArray(selectedSource)
      )
    ) : (
      "N/A"
    );

  const destination_flag =
    selectedDestination !== "" ? FlagsArray(selectedDestination) : "N/A";

  return (
    <>
      {sidebar.length > 0 ? (
        <ProSidebar collapsed={collapsed} breakPoint="lg">
          <>
            <FindItem ref={_FindItemRef} />
            <SidebarHeader style={{ textAlign: "left" }}>
              {/* <div className="sidebar-btn-wrapper" style={{ padding: "20px 24px" }}> */}
              <div className="block " style={{ float: "right" }}>
                {arrow}
              </div>
              {/* </div> */}

              {!collapsed && (
                <div style={{ padding: "10px 10px", fontSize: "15px" }}>
                  <label
                    style={{ marginTop: "5px" }}
                    onClick={() =>
                      _OpenSourceDestinationModel.current.handleOpen()
                    }
                  >
                    Source: &nbsp; {source_flag} {selectedSource}
                  </label>
                  <label
                    style={{ marginTop: "10px" }}
                    onClick={() =>
                      _OpenSourceDestinationModel.current.handleOpen()
                    }
                  >
                    Destination: &nbsp; {destination_flag} {selectedDestination}
                  </label>
                </div>
              )}

              {collapsed && selectedSource !== "" && (
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    marginLeft: "5px",
                    padding: "20px",
                  }}
                >
                  {source_flag}
                </div>
              )}
              {collapsed && selectedDestination !== "" && (
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    marginLeft: "5px",
                    padding: "10px 20px",
                  }}
                >
                  {destination_flag}
                </div>
              )}
            </SidebarHeader>
            <SidebarContent>
              {/* <Menu iconShape="circle">
            <MenuItem
              title="Select location"
              icon={<InfoIcon />}
              key="Select source & destination"
              onClick={() =>
                this._OpenSourceDestinationModel.current.handleOpen()
              }
            >
              Select location
            </MenuItem>
          </Menu> */}

              <SourceDestinationSelection ref={_OpenSourceDestinationModel} />

              {/* <Dummy_Package ref={_DummyPackage} /> */}

              <Menu iconShape="circle">
                <MenuItem
                  title="Find Item"
                  icon={<FiSearch />}
                  key="Find Item"
                  onClick={() => _FindItemRef.current.handleOpen()}
                >
                  Find Item
                </MenuItem>

                {menu_items}
              </Menu>
              <Menu iconShape="circle">
                <MenuItem
                  title="Dummy Package"
                  icon={<InfoIcon />}
                  key="dummy_package"
                  // onClick={() => _DummyPackage.current.handleOpen()}
                >
                  Dummy packages <Link to={"/dummy_packages"} />
                </MenuItem>
              </Menu>
            </SidebarContent>
            <SidebarFooter />
          </>
        </ProSidebar>
      ) : null}
    </>
  );
};

export default Sidebar;
