import React, { useEffect, useRef } from "react";
import Table from "../../shared/tables/material_table";
import {
  Badge,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  Select,
} from "@material-ui/core";
import $ from "jquery";
import { withSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../../shared/backdrop";
import pureIcons from "../../shared/pure_icons";
import HubProcessForm from "./_hub_process_form";
import { MenuItem } from "react-pro-sidebar";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      order_number: "",
      orderStateValue: false,
      selectedRoles: "",
    };
    this._tableRef = React.createRef();
    this._HubProcessFormRef = React.createRef();
    this._DummyPackageRef = React.createRef();
    this.selectedValue = React.createRef("All");
    this.selectedValue.current = props.initialValue || "All";
    this.shippingOptions = React.createRef();
  }

  reloadData = (fullReload = false) => {
    if (fullReload) this.setState({ muiTableKey: this.state.muiTableKey + 1 });
    this._tableRef.current.onQueryChange();
  };
  handleUpdate = (tracking, oldValue, newValue, columnDef, source) => {
    let self = this;
    let url, data;
    if (oldValue === newValue || newValue === "" || newValue === null) return;
    switch (columnDef.field) {
      case "tracking":
        url = `/order_item_stages/update_stage_tracking.json?source=${source}`;
        data = {
          tracking: tracking,
          new_tracking: newValue,
          stage: "vendor_shipped",
        };
        break;
      case "eta":
        url = `/order_item_stages/update_stage.json?source=${source}`;
        data = { tracking: tracking, eta: newValue, stage: "vendor_shipped" };
        break;
      case "group_id":
        url = `/order_item_stages/update_stage.json?source=${source}`;
        data = {
          tracking: tracking,
          group_id: newValue,
          stage: "vendor_shipped",
        };
        break;
      default:
    }
    this.setState({ working: true });
    $.ajax({
      url: url,
      type: "PATCH",
      data: data,
      dataType: "json",
      success: function (response) {
        self.setState({ working: false });
        self.reloadData();
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        self.setState({ working: false });
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };
  handleReviewStatus = (data) => {
    let self = this;
    $.ajax({
      url: `/stages/${data.order_number}/new_stage_review`,
      type: "PUT",
      data: { tracking: data.tracking, stage: "shipped_from_vendor" },
      dataType: "json",
      success: function (response) {
        self.props.enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        self.reloadData();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  };
  handleRoleFilterChange = (val) => {
    this.setState({ setSelectedRoles: val });
    this.selectedValue.current = val;
    this.reloadData();
  };

  render() {
    const columns = [
      {
        title: "Tracking",
        field: "tracking",
        sorting: false,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 400,
        },
        filtering: false,
      },
      {
        title: "Order Number",
        field: "order_number",
        sorting: false,
        filtering: false,
        editable: "never",
        render: (rowData) => (
          <Badge
            badgeContent={rowData.new_customer ? "new" : null}
            color="secondary"
          >
            {rowData.order_number}
          </Badge>
        ),
      },
      {
        title: "Invoice",
        field: "invoice",
        filtering: false,
        sorting: false,
        editable: "never",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 350,
          filtering: false,
        },
      },
      {
        title: "Vendor",
        field: "vendor",
        filtering: false,
        editable: "never",
        sorting: false,
      },
      {
        title: "Shipping Method",
        field: "shipping_option",
        sorting: false,
        editable: "never",
        filtering: true,
        lookup: { Sea: "Sea", Others: "Others" },
        cellStyle: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          width: "13%",
        },
        filterComponent: () => (
          <FormControl component="fieldset" style={{ width: "90%" }}>
            <Select
              value={this.state.selectedRoles || this.selectedValue.current}
              onChange={(e) => {
                this.handleRoleFilterChange(e.target.value),
                  (this.selectedValue.current = e.target.value);
              }}
            >
              {this.shippingOptions.current?.map((item) => {
                return (
                  <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
                    <FormControlLabel
                      value={item}
                      control={
                        <Radio checked={this.selectedValue.current == item} />
                      }
                      label={item}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ),
        render: (rowData) => <span>{rowData?.shipping_option}</span>,
      },
      {
        title: "ETA",
        field: "eta",
        type: "date",
        dateSetting: { format: "dd/MM/yyyy" },
        render: (rowData) => {
          return rowData.flag ? (
            <div style={{ color: "#FF0000" }}>
              {Moment(rowData.eta).format("DD/MM/YYYY")}
            </div>
          ) : (
            <div>{Moment(rowData.eta).format("DD/MM/YYYY")}</div>
          );
        },
        filtering: false,
      },
      {
        title: "Date",
        field: "created_at",
        filtering: false,
        editable: "never",
      },
      {
        title: "Filter",
        field: "group_id",
        sorting: false,
        lookup: {
          ISSUE: "ISSUE",
          DELIVERED: "DELIVERED",
          RETURN: "RETURN",
          EXPENSIVE: "EXPENSIVE",
          NO_TRACKING: "NO TRACKING",
          ___: "---",
        },
        emptyValue: "--",
      },
      { title: "source", field: "source", hidden: true },
      { title: "market_source", field: "market_source", hidden: true },
    ];
    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.working} />
        <HubProcessForm
          ref={this._HubProcessFormRef}
          reloadData={this.reloadData}
          orderState={this.selectedValue.current}
        />
        <Table
          title={"Shipped From the Vendors"}
          key={this.state.muiTableKey}
          options={{
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            searchAutoFocus: true,
            filtering: true,
          }}
          columns={columns}
          tableRef={this._tableRef}
          cellEditable={{
            onCellEditApproved: (
              newValue,
              oldValue,
              rowData,
              columnDef,
              source
            ) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  this.handleUpdate(
                    rowData.raw_tracking,
                    oldValue,
                    newValue,
                    columnDef,
                    rowData.source
                  );
                  resolve();
                }, 500);
              });
            },
          }}
          actions={[
            {
              icon: () => <pureIcons.FiRefreshCw />,
              onClick: () => {
                this.reloadData();
              },
              isFreeAction: true,
              tooltip: "Refresh",
            },
            (rowData) => ({
              icon: () => <pureIcons.GiBoxUnpacking />,
              tooltip: <span>Ship {rowData.vendor}</span>,
              onClick: (event, rowData) => {
                this._HubProcessFormRef.current.handleOpen(
                  rowData.raw_tracking,
                  rowData.order_number,
                  rowData.vendor,
                  rowData.invoice,
                  rowData.source,
                  rowData.market_source
                );
              },
              position: "row",
            }),
            (rowData) => ({
              icon: () => <pureIcons.MdPreview />,
              tooltip:
                rowData.is_reviewed === true ? (
                  <span>Reviewed By: {rowData.reviewer}</span>
                ) : (
                  <span>Mark as Reviewed</span>
                ),
              onClick: (event, rowData) => {
                window.confirm(
                  `Are you sure you want to change status to be { REVIEWED } ?`
                ) && this.handleReviewStatus(rowData);
              },
              position: "row",
              disabled: rowData.is_reviewed === true,
            }),
            // rowData => ({
            //   icon: () => <BsChatDots />,
            //   tooltip: rowData.notes.length !== 0 && <List dense={true}>
            //     {rowData.notes.map(note =>
            //       <ListItem key={note}>
            //         <ListItemText primary={note} />
            //       </ListItem>,
            //     )}
            //   </List>,
            //   position: 'row',
            //   disabled: rowData.notes.length === 0
            // })
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }

              this.selectedValue.current == "Sea"
                ? this.setState({ orderStateValue: true })
                : this.setState({ orderStateValue: false });

              const destination = localStorage.getItem("destination");
              let url = `/stages/vendor_shipped.json?location=${destination}&shipping_option=${this.selectedValue.current}&`;
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => {
                  if (!response.ok) {
                    if (response.status === 401) {
                      throw new Error(
                        "You need to sign in or sign up before continuing."
                      );
                    }
                  }
                  return response.json();
                })
                .then((result) => {
                  this.shippingOptions.current = result.shipping_methods;
                  resolve({
                    data: result.orders,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
                .catch((error) => {
                  if (
                    error.message ===
                    "You need to sign in or sign up before continuing."
                  ) {
                    window.location.href = "/";
                  } else {
                    console.error("Error:", error);
                  }
                });
            })
          }
        />
      </div>
    );
  }
}
export default withSnackbar(Index);
