import React from 'react'
import $ from 'jquery'
import {withStyles} from '@material-ui/core/styles'
import {
  Button,
  Card,
  Badge,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import Modal from '../shared/modal/material_dialog'
import {withSnackbar} from 'notistack'
import Backdrop from '../shared/backdrop'
import AsyncSearchBar from '../shared/react_async_autocomplete'

class EditCoreUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: false,
      list: [],
      errors: {},
      user: {}
    }
  }

  handleOpen = (user, list) => {
    this.setState({open: true, user: user, list: list})
  }

  handleClose = () => {
    this.setState({open: false})
  }

  handleSubmit = event => {
    let self = this
    event.preventDefault()
    this.setState({working: true})
    $.ajax({
      url: `/core_users/${this.state.user.id}/update_roles`,
      type: 'PUT',
      data: {user_roles: this.state.user.user_roles},
      dataType: 'json',
      success: function (response) {
        self.props.enqueueSnackbar('Roles Successfully Updated!', {
          variant: 'success'
        })
        self.setState({working: false})
        self.props.reloadData()
        self.handleClose()
      },
      error: function (response) {
        self.setState({working: false})
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
      }
    })
  }

  handleChange = e => {
    var array = this.state.user.user_roles
    const {name, checked} = e.target
    if (checked) {
      this.setState({
        user: {...this.state.user, user_roles: array.concat(name)}
      })
    } else {
      var itemsToDelete = array.filter(word => word === name)
      let newArray = array.filter(x => !itemsToDelete.includes(x))
      this.setState({user: {...this.state.user, user_roles: newArray}})
    }
  }
  handleSelectChange = object => {
    const id = object ? object.value : this.state.id
    this.setState({id: id})
    let self = this
    $.ajax({
      url: '/core_users/' + id,
      type: 'GET',
      dataType: 'json',
      success: function (response) {
        self.setState({
          user: {
            user_roles: response.user_roles,
            other_roles: response.other_roles,
            id: response.id
          }
        })
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: 'error'
        })
        self.setState({working: false})
      }
    })
  }

  render() {
    const {classes} = this.props
    const body = (
      this.state.user.user_roles && (
        <Card className={classes.root} style={{minHeight: '30vh'}}>
          <Backdrop open={this.state.working}/>

          <div className='container pb-5'>
            <div className='row'>
              {this.state.list.map(
                m =>
                  this.state.list.indexOf(m) % 3 === 0 && (
                    <div className='col-4'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.user.user_roles.indexOf(m[0]) !== -1
                            }
                            onChange={this.handleChange}
                            name={m[0]}
                            color='primary'
                          />
                        }
                        label={m[1]}
                      />
                    </div>
                  )
              )}
              {this.state.list.map(
                m =>
                  this.state.list.indexOf(m) % 3 === 1 && (
                    <div className='col-4'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.user.user_roles.indexOf(m[0]) !== -1
                            }
                            onChange={this.handleChange}
                            name={m[0]}
                            color='primary'
                          />
                        }
                        label={m[1]}
                      />
                    </div>
                  )
              )}
              {this.state.list.map(
                m =>
                  this.state.list.indexOf(m) % 3 === 2 && (
                    <div className='col-4'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.user.user_roles.indexOf(m[0]) !== -1
                            }
                            onChange={this.handleChange}
                            name={m[0]}
                            color='primary'
                          />
                        }
                        label={m[1]}
                      />
                    </div>
                  )
              )}
            </div>
            <div className='row'>
              {this.state.user?.other_roles.map(
                m =>
                  this.state.user.other_roles.indexOf(m) % 3 === 0 && (
                    <div className='col-4'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={true}
                            disabled={true}
                            name={m}
                            color='primary'
                          />
                        }
                        label={m}
                      />
                    </div>
                  )
              )}
              {this.state.user?.other_roles.map(
                m =>
                  this.state.user.other_roles.indexOf(m) % 3 === 1 && (
                    <div className='col-4'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={true}
                            disabled={true}
                            name={m}
                            color='primary'
                          />
                        }
                        label={m}
                      />
                    </div>
                  )
              )}
              {this.state.user?.other_roles.map(
                m =>
                  this.state.user.other_roles.indexOf(m) % 3 === 2 && (
                    <div className='col-4'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={true}
                            disabled={true}
                            name={m}
                            color='primary'
                          />
                        }
                        label={m}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        </Card>
      )

    )
    const actions = (
      <Button
        variant='contained'
        type='submit'
        style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
        disabled={this.state.working}
        hidden={this.state.page === 2}
        onClick={this.handleSubmit}
      >
        {this.state.working ? 'Working...' : 'Submit'}
      </Button>
    )

    return (
      <Modal
        isOpen={this.state.open}
        title={
          this.state.user?.admin ? (
            <Badge badgeContent='ADMIN' color='secondary'>
              {this.state.user?.full_name}
            </Badge>
          ) : (
            this.state.user?.full_name
          )
        }
        handleClose={this.handleClose}
        minHeight='40vh'
        maxHeight='60%'
        maxWidth='sm'
        actions={actions}
      >
        {body}
      </Modal>
    )
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
})

export default withSnackbar(withStyles(useStyles)(EditCoreUser))
