import React, { useState, useImperativeHandle, forwardRef } from "react";
import $ from "jquery";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, FormControlLabel, Switch } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import { useSnackbar } from "notistack";

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const AutoControlPanel = forwardRef((_: any, ref: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [brands, setBrands] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      getVendors();
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const getVendors = () => {
    setLoading(true);
    fetch("/global_orders/auto_vendors_status.json?tracking=true")
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        setBrands(result.brands);
      });
  };

  const IOSSwitch: any = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }: any) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const handleChange = (event) => {
    setBrands(
      brands.map((el) =>
        el.name === event.target.name
          ? { ...el, active: event.target.checked }
          : el
      )
    );
    setSubmitting(true);

    $.ajax({
      url: `/global_orders/update_auto_vendor_status?tracking=true`,
      type: "POST",
      data: {
        new: JSON.stringify({
          name: event.target.name,
          active: event.target.checked,
        }),
      },
      dataType: "json",
      success: function (response) {
        setSubmitting(false);
      },
      error: function (response) {
        setSubmitting(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const body = (
    <div className="container">
      <div className="row">
        {brands?.map((brand) => (
          <div className="col-6" key={brand.name}>
            <FormControlLabel
              control={
                <IOSSwitch
                  defaultChecked={brand.active}
                  onChange={handleChange}
                  name={brand.name}
                  disabled={submitting}
                />
              }
              label={brand.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <>
      <Modal
        disableBackdropClick
        isOpen={open}
        title="Tracking Automation Control"
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="60%"
        maxWidth="sm"
      >
        {loading ? <CircularProgress /> : body}
      </Modal>
    </>
  );
});

export default AutoControlPanel;
