import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NoteForm from "../notes/_note_form";
import DeleteItem from "./_cancel_form";
import StagePhase from './_stage_phase'
import Show from './_show_issues'

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

export default function StageCard({stages=[], product, reloadData, source}: any) {
	const [hidden, setHidden] = React.useState<any>([]);
	const classes = useStyles();

	return	stages.map(s => <div className="card pb-2">
			<div className="card-header" style={{backgroundColor: 'rgba(109, 199, 178, 0.7)'}}>
				{s.stage}
			</div>
			{s.records.map(row => (
				<div className="card-body">
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell >ID</TableCell>
									<TableCell >Group</TableCell>
									<TableCell >Invoice</TableCell>
									<TableCell >Stage tracking</TableCell>
									{row.eta && <TableCell >ETA</TableCell>}
									<TableCell >QTY</TableCell>
									<TableCell >Processed</TableCell>
									<TableCell >Remaining</TableCell>
									<TableCell >Who</TableCell>
									<TableCell >Time</TableCell>
									<TableCell >Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow key={row.order_item_stage_id} hidden={hidden.includes(row.order_item_stage_id)}>
									<TableCell>{row.order_item_stage_id}</TableCell>
									<TableCell>{row.group_id}</TableCell>
									<TableCell style={{ width: "200px" }}>{row.invoice}</TableCell>
									<TableCell style={{ width: "200px" }}>{row.tracking}</TableCell>
									{row.eta && <TableCell>{row.eta}</TableCell>}
									<TableCell >{row.qty}</TableCell>
									<TableCell >{row.processed}</TableCell>
									<TableCell >{row.qty - row.processed}</TableCell>
									<TableCell >{row.who}</TableCell>
									<TableCell >{row.created_at}</TableCell>
									<TableCell >
										<NoteForm order_item_stage_id={row.order_item_stage_id} key={row.order_item_stage_id} notes_count={row.notes} newNoteChild={reloadData} source={source}/>
										{row.deletable && <DeleteItem product={product} order_item_stage_id={row.order_item_stage_id} qty={row.qty - row.processed} callBackDeleteItem={reloadData} stage={s.stage} source={source}/>}
										{row.phases && <StagePhase phases={row.phases}/>}
										{row.has_change_logs && <Show order_item_stage_id={row.order_item_stage_id} table={true}  source={source}/>}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			))}
		</div>
	)
}
