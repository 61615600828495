import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, Close } from "@material-ui/icons";
import PrismaZoom from "react-prismazoom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    margin: theme.spacing(2),
    maxWidth: "calc(100% - 32px)",
    maxHeight: "calc(100% - 32px)",
    width: "90vw",
    height: "90vh",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    padding: "10px 24px 28px 24px",
  },
  titleText: {
    marginRight: "auto",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 64px)", // Subtract the height of the title bar
    padding: theme.spacing(2),
    overflow: "hidden",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  navigationButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  },
  prevButton: {
    left: theme.spacing(1),
  },
  nextButton: {
    right: theme.spacing(1),
  },
}));

const ImageSliderDialog = ({ images, open, onClose, initialIndex = 0 }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const prismaZoomRef = useRef<any>(null);

  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
    if (prismaZoomRef.current) {
      prismaZoomRef.current.reset(); // Reset zoom and position when navigating to previous image
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
    if (prismaZoomRef.current) {
      prismaZoomRef.current.reset(); // Reset zoom and position when navigating to next image
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" className={classes.titleText}>
          Image {currentIndex + 1} of {images.length}
        </Typography>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.imageContainer}>
          <PrismaZoom
            ref={prismaZoomRef}
            minZoom={1}
            maxZoom={6}
            scrollVelocity={1}
            animDuration={0.25}
          >
            <img
              src={images[currentIndex]}
              alt={`Image ${currentIndex + 1}`}
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                objectFit: "contain",
              }}
            />
          </PrismaZoom>
          <IconButton
            onClick={handlePrevious}
            className={`${classes.navigationButton} ${classes.prevButton}`}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNext}
            className={`${classes.navigationButton} ${classes.nextButton}`}
          >
            <ArrowForward />
          </IconButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageSliderDialog;
