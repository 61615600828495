import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "../shared/modal/material_dialog";
// import NoteForm from "../notes/_note_form";
import NoteForm from "../notes/_note_form";
import Tab from "./_customized_tabs";
import { Badge, Button } from "@material-ui/core";

const ShowOrder = forwardRef(({}: any, ref: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<any>("");
  const [notesCount, setNotesCount] = useState<number>(0);
  const [maxWidth, setMaxWidth] = useState<string>("sm");
  const [newCustomer, setNew_Customer] = useState<boolean>(false);
  const [order, setOrder] = useState<any>({ vendors: [] });
  const [source, setSource] = useState<any>();
  const [orderInfo, setOrderInfo] = useState<any>();

  useImperativeHandle(ref, () => ({
    handleOpen(order_number, source) {
      setOpen(true);
      setOrderNumber(order_number);
      setMaxWidth(order_number ? "xl" : "sm");
      setSource(source);
      let order_id = order_number;
      const url = `/orders/show_order.json?order_number=${order_id}&source=${source}`;
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          setOrderInfo(result);
        });
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const reset = () => {
    setOrderNumber(null);
    setMaxWidth("sm");
  };

  const setNewCustomer = (value) => {
    setNew_Customer(value);
  };

  const getOrderInfo = () => {
    let order_id = orderNumber;
    const url = `/orders/show_order.json?order_number=${order_id}&source=${source}`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setOrderInfo(result);
      });
  };

  const handleSelectChange = (object) => {
    setOrderNumber(object.value[1]);
    setMaxWidth("xl");
  };

  const orderTitle = (
    <>
      <p>
        {orderNumber}
        <NoteForm
          order_id={orderInfo?.id}
          key={orderNumber}
          notes_count={order.notes_count}
          source={source}
          orderNumber={orderNumber}
          newNoteChild={() => setNotesCount(notesCount + 1)}
        />
        <button
          className="btn btn-link"
          type="button"
          hidden={!orderNumber}
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
          variant="contained"
        >
          order info
        </button>
      </p>
      <div className="collapse" id="collapseExample">
        <div className="card card-body">
          <div className="row">
            <div className="col-3">
              <small>
                Customer Id: <b>{orderInfo?.customer_id}</b>
              </small>
            </div>
            <div className="col-3">
              <small>
                Customer Number: <b>{orderInfo?.customer_number}</b>
              </small>
            </div>
            {orderInfo?.order_summary?.map(
              (o: any) =>
                o.value !== 0 && (
                  <div className="col-3">
                    <small>
                      {o.name}: <b>{o.value}</b>
                    </small>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Modal
      disableBackdropClick={false}
      isOpen={open}
      title={orderNumber && orderTitle}
      handleClose={handleClose}
      minHeight="30%"
      maxWidth={maxWidth}
      maxHeight="85%"
    >
      <Tab
        order_number={orderNumber}
        source={source}
        callBack={setNewCustomer}
        orderInfo={orderInfo}
      />
    </Modal>
  );
});

export default ShowOrder;
