import React, { useEffect, useState } from "react";
import ShowProductCard from "./show/_product_card";
import ProductCardSkeleton from "../shared/skeletons/product_card_skeleton";

const ShowOrder = ({ source, order_number, callBack, orderInfo }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [order_numbers, setOrderNumber] = useState<string>("");
  const [notes_count, setNotesCount] = useState<number>(0);
  const [order, setOrder] = useState<any>({ vendors: [] });
  const [working, setWorking] = useState<boolean>(true);

  useEffect(() => {
    setWorking(true);
    // const url = `/orders/show_order.json?order_number=${order_number}&source=${source}`;
    // fetch(url)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     setOrder(result);
    //     setWorking(false);
    //     callBack(result.new_customer);
    //   });
    setOrder(orderInfo);
    setWorking(false);
    callBack(orderInfo?.new_customer);
  }, [orderInfo]);

  const body = order?.vendors.map((vendor) => (
    <div className="card p-1">
      <div
        className="card-header"
        style={{ backgroundColor: "rgba(109, 199, 178, 0.7)" }}
      >
        {vendor.name}
      </div>
      {vendor.products.map((product) => (
        <ShowProductCard product={product} source={source} />
      ))}
    </div>
  ));

  return <>{working ? <ProductCardSkeleton /> : body}</>;
};

export default ShowOrder;
