import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { useSnackbar } from "notistack";
import Modal from "../shared/modal/material_dialog";
import Backdrop from "../shared/backdrop";
import Webcam from "react-webcam";
import $ from "jquery";
import { ReactPhotoEditor } from "react-photo-editor";
import Barcode from "react-barcode";
import pureIcons from "../shared/pure_icons";
import { QRCodeSVG } from "qrcode.react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import NoteDummyPackage from "./note_dummy_package";
import CardTimeline from "../notes/_note_time_line";
import ImageSlider from "./img_slider";

const FormPage = forwardRef((_: any, ref: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [operation, setOperation] = useState<string>("Add");
  const [tracking_number, setTrackingNumber] = useState<string>("");
  const [notes, setNotes] = useState<any>([]);
  const [currentPicture, setCurrentPicture] = useState<any>(null);
  const [filesList, setFilesList] = useState<any>([]);
  const [showImgList, setShowImgList] = useState<any>([]);
  const webcamRef = useRef<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [UploadedImgList, setUploadedImgList] = useState<any>([]);
  const [uniqueBarcode, setUniqueBarcode] = useState<any>();
  const [imageOpen, setImageOpen] = useState<boolean>(false);
  const [packageTypeList, setPackageTypeList] = useState<any>([]);
  const [notesId, setNotesId] = useState<any>();
  const [note, setNote] = useState<string>("");
  const [errors, setErrors] = useState<any>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [allStates, setAllStates] = useState<any>();
  const [ImgList, setImgList] = useState<any>([]);
  const imageSliderRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    handleOpen(data, rowData, status) {
      if (data == "Edit") {
        setPackageType({ name: rowData.status });
        setNotesId(rowData.id);
        getShowData(rowData.id);
        setTrackingNumber(rowData.tracking);
        setSelectedMarketSource({ name: rowData.market_source });
      } else {
        setNotes([]);
      }
      setOperation(data);
      setOpen(true);
      UploadedImgList.length > 0
        ? setUniqueBarcode((prev) => prev)
        : setUniqueBarcode(generateUniqueNumber());
      let packageTypeData = status?.map((value, index) => {
        return {
          id: index,
          name: value,
        };
      });
      setPackageTypeList(packageTypeData);
    },
  }));

  useEffect(() => {}, [operation]);

  const searchParams = new URLSearchParams(window.location.search);
  const sourceFromUrl = searchParams.get("source");

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    const generateUniqueImageName = () => {
      return (
        "IMG" + "_" + Math.floor(100000 + Math.random() * 900000) + "." + "jpeg"
      );
    };

    const file = new File([blob], generateUniqueImageName(), {
      type: "image/jpeg",
      lastModified: new Date(),
    });
    setCurrentPicture(file);
    setShowModal(true);
  }, [webcamRef]);

  const [camModal, setCamModal] = useState<boolean>(false);
  const [isShowImage, setIsShowImage] = useState<boolean>(false);
  const marketList: any = [
    {
      id: "us",
      name: "US",
    },
    {
      id: "tr",
      name: "TR",
    },
    {
      id: "ae",
      name: "AE",
    },
    {
      id: "cn",
      name: "CN",
    },
    {
      id: "sa",
      name: "SA",
    },
    {
      id: "iq",
      name: "IQ",
    },
    {
      id: "qa",
      name: "QA",
    },
  ];
  const [selectedMarketSource, setSelectedMarketSource] = useState<any>({});
  const [package_type, setPackageType] = useState<any>({});
  const [statusValue, setStatusValue] = useState<any>({});
  const [statusId, setStatusId] = useState<number>();
  const [showModal, setShowModal] = useState(false);

  const getCases = () => {
    fetch("/", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        // setCasesList(res.list);
      })
      .catch((err) => {});
  };

  const getShowData = (id) => {
    $.ajax({
      url: `/dummy_packages/${id}.json`,
      type: "GET",
      dataType: "json",
      processData: false,
      contentType: false,
      success: function (response) {
        setUploadedImgList(response.images);
        setNotes(response.notes);
      },
      error: function (error) {
        enqueueSnackbar(error.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  useEffect(() => {
    getCases();
  }, []);

  const addClick = () => {
    const data = new FormData();
    const payload: any = {
      tracking: tracking_number,
      package_type: package_type?.name || "not in the system",
      market_source: sourceFromUrl,
      barcode: uniqueBarcode,
      images_attributes: filesList.concat(ImgList),
    };
    data.append("note", note);
    for (let dataKey in payload) {
      if (dataKey === "images_attributes") {
        for (var i = 0; i < payload[dataKey].length; i++) {
          data.append(
            `dummy_package[images_attributes[]][image]`,
            payload[dataKey][i]
          );
        }
      } else {
        data.append(`dummy_package[${[dataKey]}]`, payload[dataKey]);
      }
    }
    setLoader(true);
    $.ajax({
      url: `/dummy_packages`,
      type: "POST",
      data: data,
      dataType: "json",
      // enctype: "multipart/form-data",
      // cache: false,
      processData: false,
      contentType: false,
      success: function (response) {
        enqueueSnackbar("Submitted successfully", {
          variant: "success",
        });
        setOpen(false);
        setLoader(false);
        setOperation("Edit");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setUploadedImgList([]);
      },
      error: (error) => {
        setLoader(false);
        enqueueSnackbar(error.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const editClick = async () => {
    const dataImage = new FormData();
    const payload: any = {
      tracking: tracking_number,
      package_type: package_type?.name,
      market_source: selectedMarketSource?.name,
      barcode: uniqueBarcode,
      images_attributes: filesList.concat(ImgList),
      status: package_type.name,
    };

    for (let dataKey in payload) {
      if (dataKey === "images_attributes") {
        for (var i = 0; i < payload[dataKey].length; i++) {
          dataImage.append(
            `dummy_package[images_attributes[]][image]`,
            payload[dataKey][i]
          );
        }
      } else {
        dataImage.append(`dummy_package[${[dataKey]}]`, payload[dataKey]);
      }
    }
    dataImage.append("note", note);
    setLoader(true);
    if (Object.keys(statusValue).length !== 0) {
      await $.ajax({
        url: `/dummy_packages/${notesId}`,
        type: "PUT",
        data: dataImage,
        dataType: "json",
        // enctype: "multipart/form-data",
        // cache: false,
        processData: false,
        contentType: false,
        success: function (response) {
          enqueueSnackbar("Updated successfully", {
            variant: "success",
          });
          setOpen(false);
          setLoader(false);
          setOperation("Edit");
          setUploadedImgList([]);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        error: (error) => {
          setLoader(false);
          enqueueSnackbar(error.responseJSON.error, {
            variant: "error",
          });
        },
      });
    } else {
      setLoader(false);
      enqueueSnackbar("Please select status", {
        variant: "error",
      });
    }
  };

  const onsubmit = () => {
    if (operation === "Add") {
      addClick();
    } else if (operation === "Edit") {
      editClick();
    }
  };

  // Hide modal
  const hideModal = () => {
    setShowModal(false);
  };

  // Save edited image
  const handleSaveImage = (editedFile) => {
    setFilesList([...filesList, editedFile]);

    let fr = new FileReader();
    fr.onload = function () {
      setShowImgList([...showImgList, fr.result]);
    };
    fr.readAsDataURL(editedFile);

    setShowModal(false);
  };

  const clearPreview = () => {
    setFilesList([]);
    setShowImgList([]);
  };

  const modalActions = (
    <Button
      type="button"
      style={{
        backgroundColor: "#ec9a53",
        color: "#ebeef0",
        textTransform: "none",
      }}
      onClick={() => setCamModal(false)}
    >
      Save
    </Button>
  );

  const livePicture = () => {
    return (
      <Modal
        isOpen={camModal}
        handleClose={() => {
          setCamModal(false);
          clearPreview();
        }}
        title="Take live picture"
        minHeight="40vh"
        maxHeight="90%"
        maxWidth="xl"
        actions={modalActions}
      >
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "10%",
            }}
          >
            <Webcam
              style={{ width: "20vw", height: "15vw" }}
              audio={false}
              mirrored
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
            <Button
              onClick={capture}
              style={{ margin: "20px 0px", textTransform: "none" }}
            >
              Capture
            </Button>

            {showImgList?.map((val: any) => {
              return (
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    alt="pic"
                    src={val}
                    style={{
                      width: "20vw",
                      height: "15vw",
                      margin: "20px 0px",
                    }}
                  />
                </Box>
              );
            })}
          </Box>

          <ReactPhotoEditor
            open={showModal}
            onClose={hideModal}
            file={currentPicture}
            onSaveImage={handleSaveImage}
            downloadOnSave={false}
          />
        </Box>
      </Modal>
    );
  };

  const generateUniqueNumber = () => {
    return Math.floor(100000000 + Math.random() * 900000000);
  };

  const onRefreshData = () => {
    const data = new FormData();
    data.append("barcode", uniqueBarcode);
    $.ajax({
      url: `/dummy_packages/get_images?barcode=${uniqueBarcode}`,
      type: "GET",
      dataType: "json",
      // enctype: "multipart/form-data",
      // cache: false,
      processData: false,
      contentType: false,
      success: function (response) {
        if (response.images.length > 0) {
          enqueueSnackbar("Image loaded successfully", {
            variant: "success",
          });
          setUploadedImgList(response.images);
          setImageOpen(false);
          setLoading(false);
        } else {
          enqueueSnackbar("First scan QR code and upload image from mobile", {
            variant: "error",
          });
        }
      },
      error: (error) => {
        setLoading(false);
        enqueueSnackbar(error.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const validate = (fieldValues = allStates) => {
    let temp = { ...errors };
    temp.serverErrors = "";
    if ("note" in fieldValues) {
      temp.note = fieldValues.note ? "" : "Required";
    }
    setErrors({ ...temp });

    if (fieldValues === allStates) {
      return Object.values(temp).every((x) => x === "");
    } else {
      return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNote(value);
    validate({ [name]: value });
    setAllStates({ ...allStates, note: value });
  };

  const handleCloseModal = () => {
    setOpen(false);
    setTrackingNumber("");
    setPackageType({});
    setStatusValue({});
    setUploadedImgList([]);
    setFilesList([]);
    setNote("");
    setImgList([]);
  };

  const onSetImage = async (event) => {
    const files = Array.from(event.target.files);
    setImgList((prevImg) => [...prevImg, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImgList((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const openImageSlider = () => {
    setOpenImage(true);
  };

  const actions = (
    <>
      {/* <TextField
        name="note"
        label="Note"
        style={{ margin: 8 }}
        value={note}
        fullWidth
        multiline
        disabled={submitting}
        onChange={handleInputChange}
        {...(errors.note && {
          error: true,
          helperText: errors.note,
        })}
        margin="normal"
      /> */}

      {/* {submitting ? (
        <CircularProgress className="mt-3" />
      ) : (
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          type="submit"
          className="mt-3"
          disabled={submitting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      )} */}
      {operation == "Edit" ? (
        <FormControl
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: "4rem",
          }}
          size="small"
        >
          <Typography style={{ marginRight: "2rem" }}>Status: </Typography>
          <Select
            id="package_type"
            value={package_type?.name}
            style={{ width: "215px", background: "white", paddingLeft: "1rem" }}
            variant="outlined"
            onChange={(ev) => {
              setPackageType(
                packageTypeList?.filter(
                  (val) => val.name === ev.target.value
                )[0]
              );
              setStatusValue(
                packageTypeList?.filter(
                  (val) => val.name === ev.target.value
                )[0]
              );
              setStatusId(
                packageTypeList?.filter(
                  (val) => val.name === ev.target.value
                )[0]
              );
            }}
          >
            {packageTypeList?.map((value: any) => {
              return (
                <MenuItem key={value.name} value={value.name}>
                  {value.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
      <div style={{ marginLeft: "5rem", marginRight: "1rem" }}>
        <Button
          type="button"
          style={{
            backgroundColor: "#ec9a53",
            color: "#ebeef0",
            textTransform: "none",
          }}
          onClick={onsubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );

  const body = (
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1 },
        display: "flex",
        justifyContent: "space-between",
        background: "white",
        padding: "2rem",
      }}
      noValidate
      autoComplete="off"
    >
      <Box sx={{ width: "70%" }}>
        {operation == "Add" ? (
          <>
            <Box
              style={{
                display: "flex",
                marginTop: "20px",
              }}
            >
              <div>
                <Typography>Tracking number:</Typography>
              </div>
              <div style={{ marginLeft: "12.5%", width: "79%" }}>
                <TextField
                  id="tracking_number"
                  value={tracking_number}
                  variant="outlined"
                  size="small"
                  style={{ width: "31.5%" }}
                  onChange={(ev) => setTrackingNumber(ev.target.value)}
                  placeholder="  Enter tracking number"
                />
              </div>
            </Box>

            {/* <FormControl
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "20px",
            }}
            size="small"
          >
            <Typography style={{ position: "absolute" }}>
              Market source:
            </Typography>
            <Select
              id="market_source"
              value={selectedMarketSource?.name}
              style={{ marginLeft: "20%", minWidth: "25%" }}
              variant="outlined"
              onChange={(ev) =>
                setSelectedMarketSource(
                  marketList?.filter((val) => val.name === ev.target.value)[0]
                )
              }
            >
              {marketList.map((val: any) => (
                <MenuItem key={val.name} value={val.name}>
                  {val.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          </>
        ) : // <FormControl
        //   style={{
        //     display: "flex",
        //     flexDirection: "row",
        //     alignItems: "center",
        //     marginTop: "20px",
        //   }}
        //   size="small"
        // >
        //   <Typography>
        //     {operation === "Add" ? "Package type:" : "Status"}
        //   </Typography>
        //   <Select
        //     id="package_type"
        //     value={package_type?.name}
        //     style={{ marginLeft: "19%", minWidth: "25%" }}
        //     variant="outlined"
        //     onChange={(ev) => {
        //       setPackageType(
        //         packageTypeList?.filter(
        //           (val) => val.name === ev.target.value
        //         )[0]
        //       );
        //       setStatusId(
        //         packageTypeList?.filter(
        //           (val) => val.name === ev.target.value
        //         )[0]
        //       );
        //     }}
        //   >
        //     {packageTypeList?.map((value: any) => {
        //       return (
        //         <MenuItem key={value.name} value={value.name}>
        //           {value.name}
        //         </MenuItem>
        //       );
        //     })}
        //   </Select>
        // </FormControl>
        null}

        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              <Typography>Live picture upload:</Typography>
            </div>
            {/*<TextField
          id="image_upload"
          //   value={image_upload}
          variant="outlined"
          type="file"
          name="uplaod-image"
          inputProps={{
            multiple: true,
            // accept: "image/png, image/jpg, image/jpeg",
          }}
          size="small"
          style={{ marginLeft: "20%", marginRight: "1%" }}
          onChange={(ev) => handleFileChange(ev.target?.files)}
          placeholder="Upload image"
        />
        &nbsp;Or&nbsp;*/}
            <div style={{ marginLeft: "10.5%" }}>
              <Button
                style={{
                  textTransform: "none",
                  background: "#dadada",
                }}
                onClick={() => {
                  if (currentPicture) setCurrentPicture(null);
                  setCamModal(true);
                }}
              >
                Take live picture
              </Button>
            </div>
          </Box>
          {/* <Box>
        <QRCodeSVG
          value={`${
            window.location.href.split("/dummy")[0]
          }/dummy_packages/image_upload_form?barcode=${uniqueBarcode}`}
        />
      </Box>
      <Box>
        <Button
          type="button"
          style={{
            backgroundColor: "#ec9a53",
            color: "#ebeef0",
            textTransform: "none",
          }}
          onClick={onRefreshData}
        >
          Refresh
        </Button>
      </Box> */}
          {/* <Box
        style={{
          marginTop: "20px",
        }}
        sx={{ display: "flex" }}
      >
        <div>
          <Typography>Barcode:</Typography>
        </div>
        <div
          onClick={() => onOpenImageSection()}
          style={{ cursor: "pointer", marginLeft: "17%" }}
        >
          <Barcode value={uniqueBarcode} height={25} width={1.04} />
        </div>
      </Box> */}
          <Box
            style={{
              marginTop: "20px",
            }}
            sx={{ display: "flex" }}
          >
            <div>
              <Typography>QR Code:</Typography>
            </div>
            <div
              style={{
                cursor: "pointer",
                marginLeft: "17%",
                display: "flex",
              }}
            >
              <QRCodeSVG
                value={`${
                  window.location.href.split("/dummy")[0]
                }/dummy_packages/image_upload_form?barcode=${uniqueBarcode}`}
              />
              <Box sx={{ padding: "3rem 0rem 0rem 6rem" }}>
                <Button
                  type="button"
                  style={{
                    backgroundColor: "#ec9a53",
                    color: "#ebeef0",
                    textTransform: "none",
                  }}
                  onClick={onRefreshData}
                >
                  Refresh
                </Button>
              </Box>
            </div>
          </Box>

          <Box
            style={{
              marginTop: "20px",
              display: "flex",
            }}
          >
            <div>
              <Typography>Local Image Upload:</Typography>
            </div>
            <div style={{ marginLeft: "10%" }}>
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(event) => onSetImage(event)}
              />
              <div style={{ marginTop: "20px", position: "relative" }}>
                {ImgList.map((image, index) => (
                  <>
                    <img
                      key={index}
                      src={URL.createObjectURL(image)}
                      alt={`Image ${index}`}
                      height="90px"
                      width="100px"
                    />
                    <button
                      style={{
                        padding: "0",
                        border: "0",
                        position: "absolute",
                      }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <pureIcons.Delete />
                    </button>
                  </>
                ))}
              </div>
            </div>
          </Box>
        </>

        {UploadedImgList.length > 0 ? (
          <Box sx={{ display: "flex", marginTop: "20px" }}>
            <div>
              <Typography>Uploaded Images:</Typography>
            </div>
            <div
              style={{ marginLeft: "9%", cursor: "pointer" }}
              onClick={() => openImageSlider()}
            >
              {UploadedImgList.map((image) => (
                <img
                  style={{ marginLeft: "2rem" }}
                  key={image.image_id}
                  src={image}
                  alt={`Image ${image.image_id}`}
                  height="100px"
                  width="120px"
                />
              ))}
            </div>
          </Box>
        ) : null}
      </Box>
      <Box sx={{ width: "30%" }}>
        <Box sx={{ height: "50vh", overflowY: "scroll" }}>
          <CardTimeline notes={notes} />
        </Box>
        <Box sx={{ width: "100%" }}>
          <TextField
            name="note"
            label="Note"
            style={{ margin: 8 }}
            value={note}
            fullWidth
            multiline
            disabled={submitting}
            onChange={handleInputChange}
            {...(errors.note && {
              error: true,
              helperText: errors.note,
            })}
            margin="normal"
          />
        </Box>
        {/* {actions} */}
      </Box>
    </Box>
  );

  const onClose = () => {
    setOpenImage(false);
  };

  return (
    <>
      <Backdrop open={loader} />
      <ImageSlider
        open={openImage}
        onClose={onClose}
        images={UploadedImgList}
      />
      <Modal
        isOpen={open}
        handleClose={() => handleCloseModal()}
        title={
          operation === "Add" ? "Add dummy package" : "Update dummy package"
        }
        minHeight="40vh"
        maxHeight="90%"
        maxWidth="xl"
        actions={actions}
      >
        {body}
      </Modal>
      {livePicture()}
    </>
  );
});

export default FormPage;
