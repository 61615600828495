import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import PropTypes from "prop-types";

const AsyncSearchBar = ({ setCallBack, noOptionsMessage, placeholder, url}) => {
	//set default query terms
	const [query, setQuery] = useState("");

	//get animated components wrapper
	const animatedComponents = makeAnimated();

	// fetch filteres search results for dropdown
	const loadOptions = (query, callback) => {
		setTimeout(() => {
			if(query.length < 3) return callback([]);
			return fetch(`${url}?per_page=99999&search=${query}`)
			.then(response => response.json())
			.then(data => callback(data.records))
		}, 500);
	};

	return (
		<AsyncSelect
			cacheOptions
			isClearable
			autoFocus
			components={animatedComponents}
			getOptionLabel={(e) => e.label}
			getOptionValue={(e) => e.value}
			loadOptions={loadOptions}
			noOptionsMessage={() => noOptionsMessage}
			placeholder={placeholder}
			onInputChange={(value) => setQuery(value)}
			onChange={(value) => setCallBack(value)}
			menuPlacement="auto"
			maxMenuHeight={250}
		/>
	);
};

AsyncSearchBar.propTypes = {
	url: PropTypes.string.isRequired,
	noOptionsMessage: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	setCallBack: PropTypes.func.isRequired
}
export default AsyncSearchBar;
