import React, { useEffect, useState } from "react";
import Table from "../shared/tables/material_table";
import Backdrop from "../shared/backdrop";
import { Badge } from "@material-ui/core";
import $ from "jquery";
import { useSnackbar } from "notistack";

const PackingIssue = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [statusData, setStatusData] = useState<any>({});

  const { enqueueSnackbar } = useSnackbar();

  const getData = () => {
    setLoading(true);
    const url = `package_issues.json`;
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setTableData(result?.packing_issues);
        const modifiedStatus = Object.fromEntries(
          result?.statuses?.map((item) => [item, item])
        );
        setStatusData(modifiedStatus);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUpdate = (newValue: any, data: any, columnDef: any) => {
    if (newValue === "" || newValue === null) return;
    setLoading(true);
    const payload = {
      id: data.id,
      status: newValue,
    };
    $.ajax({
      url: `/package_issues/${data.id}/switch_status.json`,
      type: "POST",
      data: payload,
      dataType: "json",
      success: function (response) {
        getData();
        enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        setLoading(false);
      },
      error: function (response) {
        enqueueSnackbar(data.responseJSON.error, {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const columns = [
    {
      title: "Image",
      field: "image",
      sorting: false,
      filtering: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            {row?.images?.map((img) => {
              return (
                <img
                  style={{
                    height: "60px",
                    width: "60px",
                    objectFit: "contain",
                  }}
                  src={img}
                ></img>
              );
            })}
          </Badge>
        </div>
      ),
      editable: "never",
    },
    {
      title: "Tracking",
      field: "tracking",
      filtering: false,
      sorting: false,
      editable: "never",
    },
    {
      title: "Message Id",
      field: "message_id",
      sorting: false,
      filtering: false,
      editable: "never",
    },
    { title: "SKU", field: "sku", filtering: false, editable: "never" },
    {
      title: "Status",
      field: "status",
      filtering: true,
      lookup: statusData,
    },
    {
      title: "Issues Code",
      field: "issues",
      sorting: false,
      filtering: false,
      render: (row) => (
        <ul>
          {row.issues?.map((val) => {
            return (
              <li
                style={{
                  listStyleType: "none",
                  borderBottom: "1px solid gray",
                  // width: 200,
                }}
              >
                {val.code}
              </li>
            );
          })}
        </ul>
      ),
      editable: "never",
    },
    {
      title: "Issues Message",
      field: "issues",
      sorting: false,
      filtering: false,
      editable: "never",
      render: (row) => (
        <ul>
          {row.issues?.map((val) => {
            return (
              <li
                style={{
                  listStyleType: "none",
                  borderBottom: "1px solid gray",
                  // width: 200,
                }}
              >
                {val.message}
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      title: "Created At",
      field: "created_at",
      filtering: false,
      editable: "never",
    },
    {
      title: "Updated At",
      field: "updated_at",
      filtering: false,
      editable: "never",
    },
  ];

  return (
    <>
      <div className="bg-white rounded">
        {loading ? (
          <Backdrop open={loading} />
        ) : (
          <Table
            title="Package Issues"
            options={{
              toolbar: true,
              debounceInterval: 1000,
              actionsColumnIndex: -1,
            }}
            cellEditable={{
              cellStyle: {
                width: "15%",
              },
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve: any, reject) => {
                  setTimeout(() => {
                    handleUpdate(newValue, rowData, columnDef);
                    resolve();
                  }, 500);
                });
              },
            }}
            columns={columns}
            data={tableData}
          />
        )}
      </div>
    </>
  );
};

export default PackingIssue;
