
import React from 'react';
import Table from '../shared/tables/material_table';
import { withSnackbar } from 'notistack';
import { Badge } from '@material-ui/core';
import pureIcons from "../shared/pure_icons";
import Backdrop from "../shared/backdrop";
import EditCoreUser from "./edit_core_user";
import AddCoreUser from "./add_core_user";
import $ from 'jquery'

class CoreUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: true,
      muiTableKey: 0,
      total: null,
      users: []
    };
    this._tableRef = React.createRef();
    this._EditCoreUserRef = React.createRef();
    this._AddCoreUserRef = React.createRef();


  }

  handleDelete = (rowData) => {
		let self = this;
    let id= rowData.id
		this.setState({ working: true });
		$.ajax({
			url: `/core_users/${id}`,
			type: 'DELETE',
			data: id,
			dataType: 'json',
			success: function (response) {
				self.setState({ working: false });
				self.reloadUsers();
				self.props.enqueueSnackbar('successfully deleted!', { variant: 'success' })
			},
			error: function (response) {
				self.setState({ working: false });
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}

  componentDidMount() {
    this.reloadUsers();
  }

  reloadUsers = async () => {
    this.setState({working: true});
    const url = `/core_users.json?`
    await fetch(url)
      .then(response => response.json())
      .then(result => {this.setState({users: result.users, working: false, user_roles_list: result.user_roles_list })});
  }

  render() {
    const columns = [
      { title: 'Name', field: 'full_name', render: rowData => (rowData.admin ? <Badge badgeContent='ADMIN' color='secondary'>{rowData.full_name}</Badge> : rowData.full_name) },
      { title: 'Phone Number', field: 'phone_number'},
      { title: 'Roles', field: 'user_roles_pretty'},
      { title: 'Last Active', field: 'last_seen_at' },
    ]
    return (
      <div className='bg-white rounded'>
        <Backdrop open={this.state.working} />
        <EditCoreUser ref={this._EditCoreUserRef} reloadData={this.reloadUsers} user={this.state.user} list={this.state.user_roles_list} />
        <AddCoreUser ref={this._AddCoreUserRef} reloadData={this.reloadUsers} list={this.state.user_roles_list} />

        <Table
          title='System Users'
          key={this.state.muiTableKey}
          options={{
            toolbar: true,
            filtering: false,
            sorting: false,
            debounceInterval: 1000,
            actionsColumnIndex: -1
          }
          }
          columns={columns}
          tableRef={this._tableRef}
          actions={
            [
             {
                icon: () => <pureIcons.AiOutlinePlus />,
               onClick: (event, rowData) => this._AddCoreUserRef.current.handleOpen(this.state.user_roles_list),

              isFreeAction: true,
              tooltip: 'Add New User'
               },
              {
                icon: () => <pureIcons.Edit />,
                tooltip: <span>Edit</span>,
                onClick: (event, rowData) => this._EditCoreUserRef.current.handleOpen(rowData, this.state.user_roles_list),
                position: 'row'
              },
              {
                icon: () => <pureIcons.Delete />,
                tooltip: <span>Delete</span>,
                onClick: (event, rowData) => { window.confirm(`Are you sure you want to remove ${rowData.full_name} from core users?`) && this.handleDelete(rowData) },
                position: 'row'
              },
              {
                icon: () => <pureIcons.FiRefreshCw />,
                onClick: () => { this.reloadUsers() },
                isFreeAction: true,
                tooltip: 'Refresh'
              },
            ]}
          data={this.state.users}
        />
      </div>
    );
  }
}

export default withSnackbar(CoreUsers)
