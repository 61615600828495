import React, { forwardRef, useRef } from "react";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import { ImBarcode } from "react-icons/im";

const ShowBarcode = forwardRef(({ code }: any, ref: any) => {
  return (
    <div className="d-none d-print-block" ref={ref}>
      <div className="text-center align-middle">
        <Barcode value={code} height={30} width={1.25} />
      </div>
    </div>
  );
});

const PrintBarcode = ({ code }: any) => {
  const componentRef = useRef<any>();
  return (
    <div>
      <ReactToPrint
        trigger={() => <ImBarcode />}
        content={() => componentRef.current}
      />
      <ShowBarcode ref={componentRef} code={code} />
    </div>
  );
};

export default PrintBarcode;
