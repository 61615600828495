import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Card, TextField, Select, MenuItem, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import $ from "jquery";
import Backdrop from "../../shared/backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    margin: theme.spacing(1),
    width: "50ch",
  },
  textField2: {
    margin: theme.spacing(1),
    width: "22ch",
  },
  button: {},
}));

const ShippingForm = forwardRef(({ reloadData, source, location, activeCarriers }: any, ref: any) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [single, setSingle] = useState('yes');
  const [showBox, setShowBox] = useState('no');
  const [carrier, setCarrier] = useState<any>();
  const [eta, setEta] = useState<any>();

  useImperativeHandle(ref, () => ({
    handleOpen(data) {
      var valuesList = { master_waybill: "" };
      data?.map((box) => (valuesList = { ...valuesList, [box.group_id]: "" }));
      let eta = new Date();
      eta.setDate(eta.getDate() + 3 * 7);
      setOpen(true);
      setData(data);
      setValues(valuesList);
      setErrors([]);
      setCarrier("");
      setEta(eta);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    temp.serverErrors = "";
    if ("master_waybill" in fieldValues) {
      temp.master_waybill = fieldValues.master_waybill ? "" : "Required";
    }
    if ("carrier" in fieldValues) {
      temp.carrier = fieldValues.carrier ? "" : "Required";
    }
    data?.map((box) => {
      if (showBox === 'yes' && box.group_id in fieldValues) {
         temp[box.group_id] = fieldValues[box.group_id] ? "" : "Required";
      }
    });

    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validate({ [name]: value });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate()) return;
    setSubmitting(true);
    $.ajax({
      url: `/order_item_stages/add_shipper_tracking_number.json?source=${source}&location=${location}`,
      type: "POST",
      data: { values: values, data: data, carrier: carrier, single: single, showBox: showBox},
      dataType: "json",
      success: function (response) {
        setSubmitting(false);
        enqueueSnackbar("successfully processed!", { variant: "success" });
        reloadData();
        handleClose();
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, { variant: "error" });
        setSubmitting(false);
      },
    });
  };

  const handleRadioChange = (e) => {
    setSingle(e.target.value)
    if (e.target.value === 'no')
      {setShowBox('yes')}
  }

  const handleRadio2Change = (e) => {
    setShowBox(e.target.value)
    if (e.target.value === 'no') {
      setValues([]);
    }
  }

  const body = (
    <Card className={classes.root} style={{ minHeight: "30vh" }}>
      <div className="container pb-5">
        <div className="row pt-2 ps-1">
          <Select
            name="simple-select"
            className={classes.textField2}
            displayEmpty
            value={carrier}
            {...(errors.carrier && {
              error: true,
              helperText: errors.carrier,
            })}
            onChange={(event) => setCarrier(event.target.value)}
          >
            <MenuItem value="" disabled>
              Select carrier
            </MenuItem>
            {activeCarriers?.map((m) => (
              <MenuItem value={m}>{m}</MenuItem>
            ))}
          </Select>
        </div>
        <RadioGroup aria-label="single-waybill" name="single-waybill" value={single} onChange={handleRadioChange} hidden={!carrier}>
          <FormControlLabel value='yes' control={<Radio />} label="Single waybill" />
          <FormControlLabel value='no' control={<Radio />} label="Multiple waybills" />
        </RadioGroup>
        <div className="row pt-2">
          <TextField
            fullWidth
            className={classes.textField2}
            name="master_waybill"
            hidden={!carrier}
            value={values.master_waybill}
            label={single === 'yes' ? "Waybill number" : "Group number/ Master waybill"}
            {...(errors.master_waybill && {
              error: true,
              helperText: errors.master_waybill,
            })}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <RadioGroup aria-label="show-boxes" name="show-boxes" value={showBox} onChange={handleRadio2Change} hidden={!carrier}>
            <FormControlLabel value='yes' control={<Radio />} label="Box number" />
            <FormControlLabel value='no' control={<Radio />} label="No box number" />
          </RadioGroup>
        </div>
        {carrier && showBox == 'yes' && data?.map((box) => (
          <div className="row pt-2">
            <TextField
              fullWidth
              key={box.group_id}
              className={classes.textField}
              name={box.group_id}
              value={values[box.group_id]}
              label={box.group_id}
              {...(errors[box.group_id] && {
                error: true,
                helperText: errors[box.group_id],
              })}
              onChange={handleInputChange}
            />
          </div>
        ))}
      </div>
    </Card>
  );

  const actions = (
    <Button
      variant="contained"
      style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
      type="submit"
      className={classes.button}
      onClick={handleSubmit}
    >
      {submitting ? "Submitting" : "Submit"}
    </Button>
  );

  const selectedDateValue = (date) => {
    setEta(date);
    validate({ eta: date });
  };

  return (
    <div>
      <Backdrop open={submitting} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={"Carrier Tracking"}
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="sm"
        actions={actions}
      >
        {body}
      </Modal>
    </div>
  );
});

export default ShippingForm;
