import React from 'react';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions, Slide
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props}/>;
});

export default function Modal({isOpen, title='', actions='', summary = '', children, maxWidth='sm',	minHeight,
  maxHeight= '80%', aria_labelledby='', controlledColes = false, showCloseIcon = true,	disableBackdropClick = true, handleClose})
{
	const handleCloseLocal = (event, reason) => {
		if (disableBackdropClick && reason === "backdropClick") {
			return false;
		}

		if (disableEscapeKeyDown && reason === "escapeKeyDown") {
			return false;
		}

		if (typeof handleClose === "function") {
			handleClose();
		}
	};

	return (
		<Dialog
			PaperProps={{style: {minHeight: minHeight, maxHeight: maxHeight, backgroundColor: '#eff2f8'}}}
			fullWidth
			TransitionComponent={Transition}
			maxWidth={maxWidth}
			open={isOpen}
			onClose={handleCloseLocal}
			aria-labelledby={aria_labelledby}
		>
			<DialogTitle>
				{showCloseIcon && <CloseIcon style={{ fontSize: 30, cursor:'pointer', float:'right' }} onClick={handleClose}/>}
				{title}
			</DialogTitle>
			<DialogContent style={{ overflowY: 'scroll' }}>
				{children}
			</DialogContent>
			<DialogActions>
				{summary}
			</DialogActions>
			<DialogActions style={{ 'paddingBottom': '20px', 'paddingRight': '20px', 'paddingLeft': '20px'}}>
				{actions}
			</DialogActions>
		</Dialog>
	)
}
