import React, { useEffect, useRef, useState } from "react";
import { Badge, Link } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import ShowItem from "../../order_items/show";
import Table from "../../shared/tables/material_table";
import { FiRefreshCw } from "react-icons/fi";
import Barcode from "../../order_items/_barcode";
import { ImInfo } from "react-icons/im";
import UpdateStageQty from "../../order_items/_update_stage_qty";

import productData from "./showDummy.json";

const PackingList = ({ open, setOpen, groupId, setGroupId, orderState }) => {
  const [working, setWorking] = useState<any>(false);
  const [products, setProducts] = useState<any>([]);

  const _tableRef = React.createRef<any>();
  const _ShowItemRef = useRef<any>();

  const handleOpen = (group_id) => {
    setOpen(true);
    setGroupId(group_id);
    reloadData(group_id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleQtyUpdateCallback = () => {
    reloadData(groupId);
  };

  const gerSourceDestData = (input: string) => {
    if (input === "source") {
      return localStorage.getItem("source") || "";
    } else if (input === "destination") {
      return localStorage.getItem("destination") || "";
    }
  };

  const reloadData = (group_id = null) => {
    setWorking(true);
    const url = `/order_item_stages/packing_list.json?sea_shipping=${!orderState}&source=${gerSourceDestData(
      "source"
    )}&location=${gerSourceDestData("destination")}&group_id=${groupId}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.products);
        setWorking(false);
      });
  };

  useEffect(() => {
    reloadData();
  }, []);

  const columns = [
    {
      title: "",
      field: "image",
      sorting: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "60px", width: "60px", objectFit: "contain" }}
              alt={row.name}
              src={row.image}
            ></img>
          </Badge>
        </div>
      ),
    },
    {
      title: "Product Name",
      field: "name",
      sorting: false,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        width: "30%",
      },
      render: (rowData) => (
        <Link href={rowData.url} target="_blank">
          {rowData.name_short}
        </Link>
      ),
    },
    {
      title: "QTY",
      field: "qty",
      sorting: false,
      cellStyle: { width: "2%" },
    },
    { title: "Order Number", field: "order_number" },
    { title: "Code", field: "redeem_code", sorting: false },
    { title: "Date", field: "created_at" },
  ];

  const body = (
    <Table
      title={groupId}
      options={{
        toolbar: true,
        filtering: false,
        debounceInterval: 1000,
        actionsColumnIndex: -1,
        paging: false,
      }}
      columns={columns}
      actions={[
        {
          icon: () => <FiRefreshCw />,
          onClick: () => {
            reloadData();
          },
          isFreeAction: true,
          tooltip: "Refresh",
        },
        (rowData) => ({
          icon: () => <Barcode code={rowData.redeem_code} />,
          tooltip: <span>Barcode</span>,
          position: "row",
        }),
        {
          icon: () => (
            <span style={{ padding: "0rem 1rem" }}>
              <ImInfo />
            </span>
          ),
          tooltip: <span>Show</span>,
          onClick: (event, rowData) =>
            _ShowItemRef.current.handleOpen(
              rowData.order_item_id,
              gerSourceDestData("source")
            ),
          position: "row",
        },
        (rowData) => ({
          icon: () => (
            <UpdateStageQty
              product={rowData}
              table={true}
              callBackStageQty={handleQtyUpdateCallback}
              qty={rowData.qty}
              stage={"Ready to Ship"}
              location={gerSourceDestData("destination")}
              source={gerSourceDestData("source")}
            />
          ),
          tooltip: <span>Return to previous state</span>,
          position: "row",
        }),
      ]}
      data={products}
    />
  );

  return (
    <div>
      <Backdrop open={working} />
      <ShowItem ref={_ShowItemRef} reloadData={reloadData} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title=""
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
      >
        {body}
      </Modal>
    </div>
  );
};

export default PackingList;
