import React from 'react';
import { Typography, ButtonGroup, IconButton } from '@material-ui/core';
import $ from 'jquery';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Select from '../shared/autocomplete';
import Backdrop from '../shared/backdrop'
import EditIcon from '@material-ui/icons/Edit';
import Show from './_show_issues'

export default function StageIssues({ product, stage, callBackStageIssue, source }: any) {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [working, setWorking] = React.useState<boolean>(false);
	const [edit, setEdit] = React.useState<any>(product.status === null);
	const [action, setAction] = React.useState<boolean>(false);

	const handleSelectChange = (value: any) => {
		setWorking(true);
		const data = { issues_reason: value.value, order_item_stage_id: product.order_item_stage_id, source: source}
		window.confirm(value.label) &&
			$.ajax({
				url: '/order_item_stages/update_stage',
				type: 'PATCH',
				data: data,
				dataType: 'json',
				success: function () {
					setWorking(false);
					setEdit(false);
					enqueueSnackbar('successfully updated!', { variant: 'success' })
					callBackStageIssue(value.label);
				},
				error: function (response) {
					setWorking(false);
					enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
				},
			});
		setWorking(false);
	}

	return (
		<>
			<Backdrop open={working} />
			{edit &&
				<Select
					name='stage_issue'
					label='Issues'
					value={product.status}
					url={`/codes/stage_issues.json?source=${source}&stage=${stage}&current_issue=${product.status || 'The issue has been resolved.'}`}
					callBack={handleSelectChange}
				/>}
			{product.status && <div className={classes.content} onMouseEnter={() => setAction(true)} onMouseLeave={() => setAction(false)}>
				<Typography variant='subtitle1' color='textSecondary'>{product.status}</Typography>
				<IconButton className={classes.IconEditButton} hidden={!action} onClick={() => setEdit(true)}>
					<EditIcon />
				</IconButton>
				<Show order_item_stage_id={product.order_item_stage_id} hidden={!action} source={source} />
			</div>}
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	IconEditButton: {
		position: 'absolute',
		margin: '-1rem -2.5rem 0rem 0rem',
		top: '0px',
		right: '0px'
	},
	IconShowButton: {
		position: 'absolute',
		margin: '-2rem -2.5rem 0rem 0rem',
		top: '0px',
		right: '0px'
	},
	content: {
		position: 'relative'
	}
}));
