import React from 'react';
import { Paper } from '@material-ui/core';
import CardTimeline from "./_note_time_line";

export default function NoteCard({notes}: any) {
	return (
		<Paper style={{maxHeight: 300, overflow: 'auto'}}>
			<CardTimeline notes={notes}/>
		</Paper>
	);
}
