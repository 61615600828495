import React, { forwardRef } from "react";

const Invoice2 = forwardRef(({ invoice_data, products, factor, logo, signature }: any, ref: any) => {
  return (
    <div className="d-none d-print-block" ref={ref}>
      <div className="container-fluid p-3">
        <div className="pure-invoice--header">
          <table className="pdf-table">
            <tr>
              <td className="border border-bottom-0 border-5 p-2">
                <ul className="list-unstyled">
                  <li>SENT BY:</li>
                  <li>{invoice_data.user}</li>
                  <li>{invoice_data.address}</li>
                  <li>{invoice_data.country}</li>
                  <li>Tel: +1 224-200-9797</li>
                  <li>email: samer@pureplatform.com</li>
                </ul>
              </td>
              <td className="text-end">
                {invoice_data.region !== "ae" && (
                  <img src={logo} className="logo" alt="logo" />
                )}
                <h2>CUSTOMS INVOICE</h2>
              </td>
            </tr>
          </table>
          <table className="pdf-table m-0">
            <tr>
              <td className="border border-top-1 border-5 p-2">
                <ul className="list-unstyled">
                  <li>SENT TO:</li>
                  <li>Moe Al-Khafaji</li>
                  <li>Inner Karrada Street</li>
                  <li>Baghdad / Iraq</li>
                  <li>Tel: +964 773 535 4949, +964 772 537 2176</li>
                  <li>support@pureplatform.com</li>
                </ul>
              </td>
              <td className="border border-5 p-2">
                <ul className="list-unstyled">
                  <li>TERMS OF DELIVERY: DOOR TO DOOR</li>
                  <li>TOTAL GROSS WEIGHT: {invoice_data.gross_weight}</li>
                  <li>TOTAL NET WEIGHT: {invoice_data.net_weight}</li>
                  <li>NUMBER OF PIECES: {invoice_data.boxes.length}</li>
                  <div className="row">
                    {invoice_data.boxes.length > 4 &&
                      invoice_data.boxes.map((box, index) => (
                        <div className="col-6">
                          {index + 1}.{box}
                        </div>
                      ))}
                    {invoice_data.boxes.length <= 4 &&
                      invoice_data.boxes.map((box, index) => (
                        <span>
                          {index + 1}.{box}
                        </span>
                      ))}
                  </div>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        <div className="pure-invoice--body">
          <table
            className="pdf-table border border-top-0 border-5 p-2"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th className="text-start" style={{ width: "64%" }}>
                  Description
                </th>
                <th className="text-center" style={{ width: "10%" }}>
                  HS Code
                </th>
                <th className="text-center" style={{ width: "6%" }}>
                  Qty
                </th>
                <th className="text-center" style={{ width: "10%" }}>
                  Unit Value
                </th>
                <th className="text-center" style={{ width: "10%" }}>
                  Sub Total
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr>
                  <td className="text-start">{product.name_70}</td>
                  <td className="text-center">{product.hs_code}</td>
                  <td className="text-center">{product.qty}</td>
                  <td className="text-center">
                    {(product.price * factor).toFixed(2)}
                  </td>
                  <td className="text-center">
                    {(product.qty * (product.price * factor)).toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="text-end fw-bolder" colSpan={4}>
                  Total Value and Currency:
                </td>
                <td className="text-center fw-bolder">
                  $
                  {products
                    .reduce((a, v) => (a = a + v.qty * v.price * factor), 0)
                    .toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="pt-2">
            <b className="pt-2">
              I, the undersigned, hereby certify that the information on this
              invoice is true and correct and that the contents of this shipment
              are as stated above.
            </b>
          </div>
          <div className="pt-2">
            <ul className="list-unstyled">
              <img src={signature} className="signature" alt="signature" />
              <li>{invoice_data.user}</li>
              <li>{invoice_data.today}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Invoice2;
