import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import $ from "jquery";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  TextField,
  Typography,
  Input,
  InputLabel,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import ProductCard from "./_product_card";
import DatePicker from "../../shared/date_picker";
import { useSnackbar } from "notistack";
import ProductCardSkeleton from "../../shared/skeletons/product_card_skeleton";
import Backdrop from "../../shared/backdrop";
import Alert from "@material-ui/lab/Alert";
import purchased from "../purchased";
import OrderInfo from "./_order_info";
import ShowOrder from "../_show";
import Customer from "./_customer_info";
import OrdersSummary from "./_orders_summary";
import { makeStyles } from "@material-ui/core/styles";
import CopyTextComponent from "../../shared/copy_clipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {},
}));

const PurchaseForm = forwardRef(({ reloadData }: any, ref: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [orderSummary, setOrderSummary] = useState<any>([]);
  const [order_number, setOrderNumber] = useState<any>("");
  const [source, setSource] = useState<any>("");
  const [vendor, setVendor] = useState<any>("");
  const [currency, setCurrency] = useState<any>("");
  const [currencies, setCurrencies] = useState<any>([]);
  const [currency_value, setCurrencyValue] = useState<any>();
  const [customerInfo, setCustomerInfo] = useState<any>();
  const [working, setWorking] = useState<boolean>(false);
  const [purchased, setPurchased] = useState<any>([]);
  const [duplicate, setDuplicate] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<any>("");
  const [inTheCart, setInTheCart] = useState<any>(false);
  const [not_supported, setNotSupported] = useState<any>(true);
  const [invoice_total, setInvoiceTotal] = useState<any>("");
  const [eta, setEta] = useState<any>(null);
  const [group_id, setGroupId] = useState<any>();
  const [multiple_brand, setMultipleBrand] = useState<boolean>(false);
  const [invoice_warning, setInvoiceWarning] = useState<number>(0);
  const [invoice_native, setInvoiceNative] = useState<any>();
  const [market_source, setMarketSource] = useState<any>();
  const [new_customer, setNewCustomer] = useState<any>();
  const [shipping_option, setShipping_option] = useState<any>();
  const _ShowOrderRef = useRef<any>(null);
  const _tableRef = useRef<any>(null);
  const [allStates, setAllStates] = useState<any>();
  const [OrderState, setOrderState] = useState<any>();

  const classes = useStyles();

  useEffect(() => {
    setAllStates({
      open,
      errors,
      products,
      orderSummary,
      order_number,
      source,
      vendor,
      currency,
      currencies,
      currency_value,
      customerInfo,
      working,
      purchased,
      duplicate,
      invoice,
      inTheCart,
      not_supported,
      invoice_total,
      eta,
      group_id,
      multiple_brand,
      invoice_warning,
      invoice_native,
      market_source,
      new_customer,
    });
  }, [
    open,
    errors,
    products,
    orderSummary,
    order_number,
    source,
    vendor,
    currency,
    currencies,
    currency_value,
    customerInfo,
    working,
    purchased,
    duplicate,
    invoice,
    inTheCart,
    not_supported,
    invoice_total,
    eta,
    group_id,
    multiple_brand,
    invoice_warning,
    invoice_native,
    market_source,
    new_customer,
  ]);

  const reloadsData = () => {
    _tableRef.current.onQueryChange();
  };

  useImperativeHandle(ref, () => ({
    handleOpen(
      order_number,
      vendor,
      source,
      market_source,
      group_id,
      shipping_option,
      new_customer,
      orderState
    ) {
      setOpen(true);
      setOrderNumber(order_number);
      setSource(source);
      setVendor(vendor);
      setInTheCart("");
      setNotSupported("");
      setInvoice("");
      setInvoiceTotal("");
      setDuplicate(false);
      setMultipleBrand(false);
      setInvoiceWarning(0);
      setErrors({});
      setPurchased([]);
      setEta(null);
      setGroupId(group_id);
      setInvoiceNative("");
      setMarketSource(market_source);
      setNewCustomer(new_customer);
      setShipping_option(shipping_option);
      setOrderState(orderState);
      getProducts(order_number, vendor, source, orderState);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleAfterSubmit = () => {
    if (
      purchased.length === products.length &&
      purchased.reduce((a, v) => (a = a + v.qty), 0) ===
        products.reduce((a, v) => (a = a + v.qty), 0)
    ) {
      handleClose();
    } else {
      setInvoice("");
      setInvoiceTotal("");
      setEta(null);
      setPurchased([]);
      setInvoiceNative("");

      getProducts(order_number, vendor, source, OrderState);
    }
  };

  const getProducts = (
    order_number: any,
    vendor: any,
    source: any,
    orderState: any
  ) => {
    setWorking(true);
    const url = `/orders/items_to_purchase.json?source=${source}&order_number=${order_number}&sea_shipping=${orderState}&brand=${vendor}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        if (result.auto_cart_errors) {
          enqueueSnackbar(result.auto_cart_errors, {
            variant: "error",
          });
        }
        if (result?.order_items?.length <= 0) {
          enqueueSnackbar("Order item empty", {
            variant: "error",
          });
          setWorking(false);
          handleClose();
        } else {
          setProducts(result.order_items);
          setWorking(false);
          setOrderSummary(result.order_summary);
          setMultipleBrand(result.multiple_brand);
          setCurrency(result.default_currency);
          setCurrencies(result.currencies);
          setCurrencyValue(result.currency_value);
          setCustomerInfo(result.customer_info);
        }
      })
      .catch((error) => {
        setWorking(false);
        enqueueSnackbar(
          "Invalid order or items has been moved to the next stage",
          { variant: "error" }
        );
        handleClose();
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoice(value);
    validate({ [name]: value });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      if (name === "invoice_total") {
        setInvoiceTotal(value);
      } else if (name === "invoice_native") {
        setInvoiceNative(value);
      }
      setInvoiceWarning(0);
    }
    validate({ [name]: value });
  };

  const validate = (fieldValues = allStates) => {
    let temp = { ...errors };
    temp.serverErrors = "";
    if ("invoice" in fieldValues) {
      temp.invoice = fieldValues.invoice ? "" : "Required";
    }
    if ("invoice_native" in fieldValues) {
      temp.invoice_native = fieldValues.invoice_native ? "" : "Required";
    }
    if ("eta" in fieldValues) {
      temp.eta =
        fieldValues.eta === null
          ? "Required"
          : isNaN(fieldValues.eta.getTime())
          ? "Invalid Date"
          : "";
    }
    if ("purchased" in fieldValues) {
      if (fieldValues.purchased.length > 0) temp.purchased = "";
      else {
        temp.purchased = "One or more items must be selected!";
        enqueueSnackbar("One or more items must be selected!", {
          variant: "error",
        });
      }
    }
    setErrors({ ...temp });
    if (fieldValues === allStates)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (event: any) => {
    const invoice_total =
      currency === "$"
        ? invoice_native
        : (invoice_native * currency_value).toFixed(2);
    event.preventDefault();
    if (!validate()) return;
    let subtotal = purchased.reduce((a, v) => (a = a + v.qty * v.price), 0);
    if (invoice_warning !== 3 && subtotal + subtotal * 0.025 < invoice_total) {
      setInvoiceWarning(1);
      return;
    }
    if (invoice_warning !== 3 && subtotal - subtotal * 0.1 > invoice_total) {
      setInvoiceWarning(2);
      return;
    }
    setWorking(true);

    const data = {
      purchased: purchased,
      invoice: invoice,
      invoice_native: invoice_native,
      eta: eta,
      total: invoice_total,
      vendor: vendor,
      currency: currency,
      duplicate: duplicate,
      source: source,
      order_number: order_number,
      group_id: group_id,
      market_source: market_source,
      new_customer: new_customer,
      shipping_option: shipping_option,
    };
    $.ajax({
      url: `/orders/purchase?order_number=${order_number}`,
      type: "POST",
      data: data,
      dataType: "json",
      success: function (response) {
        setWorking(false);
        setDuplicate(false);
        enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        handleAfterSubmit();
        reloadData();
      },
      error: function (response) {
        if (response.responseJSON.error === "duplicate") {
          setWorking(false);
          setDuplicate(true);
          reloadData();
        } else {
          enqueueSnackbar(response.responseJSON.error, {
            variant: "error",
          });
          reloadData(true);
          setWorking(false);
        }
      },
    });
  };

  const setProductData = (data) => {
    if (data.selected) {
      let obj = purchased.find((o) => o.order_item_id === data.order_item_id);
      if (obj) {
        let replace = purchased.filter(
          (o) => o.order_item_id !== data.order_item_id
        );
        replace.push(data);
        setPurchased(replace);
      } else {
        setPurchased([...purchased, data]);
      }
    } else {
      if (data.deleted) {
        let replace = products.filter(
          (o) => o.order_item_id !== data.order_item_id
        );
        if (products.length === 1) {
          handleClose();
          reloadData();
        } else {
          setProducts(replace);
        }
      }
      let replace = purchased.filter(
        (o) => o.order_item_id !== data.order_item_id
      );
      setPurchased(replace);
    }
  };

  const handleAddToAutoCart = () => {
    setWorking(true);

    $.ajax({
      url: `/orders/add_to_auto?order_number=${order_number}&source=${source}`,
      type: "POST",
      dataType: "json",
      success: function (response) {
        setWorking(false);
        enqueueSnackbar(response.message, { variant: "success" });
        reloadData();
        handleClose();
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        reloadData();
        setWorking(false);
      },
    });
  };

  const handleClearAutoCart = () => {
    setWorking(true);
    $.ajax({
      url: `/orders/process_next_auto?vendor=${vendor}&source=${source}`,
      type: "POST",
      dataType: "json",
      success: function (response) {
        setWorking(false);
        enqueueSnackbar("successfully processed next in queue!", {
          variant: "success",
        });
        reloadData();
        handleClose();
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        setWorking(false);
        reloadData();
      },
    });
  };

  const selectedDateValue = (date) => {
    setEta(date);
    validate({ eta: date });
  };

  const body = products?.map((p) => (
    <ProductCard
      product={p}
      callBack={setProductData}
      key={p.order_item_id}
      purchased={purchased.find(
        (o) => o.order_item_stage_id === p.order_item_stage_id
      )}
      source={source}
    />
  ));

  const summary = (
    <div className="container">
      <div className="row ms-4">
        <div className="col">
          <Typography variant="button" color="textSecondary">
            Selected: {purchased.length}/{products?.length}
          </Typography>
        </div>
        <div className="col">
          <Typography variant="button" color="textSecondary">
            Items: {purchased.reduce((a, v) => (a = a + v.qty), 0)}
          </Typography>
        </div>
        <div className="col">
          <Typography variant="button" color="textSecondary">
            Subtotal: $
            {purchased
              .reduce((a, v) => (a = a + v.qty * v.price), 0)
              .toFixed(2)}
          </Typography>
        </div>
        <div className="col">
          <Typography variant="button" color="textSecondary">
            US shipping: $
            {purchased
              .reduce((a, v) => (a = a + v.shipping_to_hub), 0)
              .toFixed(2)}
          </Typography>
        </div>
      </div>
      <div className="row mt-2 ms-4">
        <div className="col">
          <TextField
            name="invoice"
            fullWidth
            value={invoice}
            label="Invoice #"
            {...(errors.invoice && {
              error: true,
              helperText: errors.invoice,
            })}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <div className="row">
            <div className="col-3">
              <TextField
                fullWidth
                label="Currency"
                select
                value={currency}
                onChange={(event) => setCurrency(event.target.value)}
              >
                {currencies?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-8">
              <TextField
                name="invoice_native"
                fullWidth
                value={invoice_native}
                label="Invoice Native Total"
                {...(errors.invoice_native && {
                  error: true,
                  helperText: errors.invoice_native,
                })}
                onChange={handleInputNumberChange}
              />
            </div>
          </div>
        </div>
        <div className="col-2">
          <TextField
            name="invoice_total"
            fullWidth
            disabled={true}
            value={
              currency === "$"
                ? invoice_native
                : (invoice_native * currency_value).toFixed(2)
            }
            label="Invoice Total"
            onChange={handleInputNumberChange}
          />
          {(invoice_warning === 1 || invoice_warning === 2) && (
            <Alert
              severity="warning"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setInvoiceWarning(3)}
                >
                  OK
                </Button>
              }
            >
              {`Invoice is ${
                invoice_warning === 1 ? "greater" : "less"
              } than subtotal`}
            </Alert>
          )}
        </div>
        <div className="col">
          <DatePicker
            disablePast={true}
            disableFuture={false}
            name="eta"
            label="ETA"
            selectedDateValue={selectedDateValue}
            value={eta}
            {...(errors.eta && {
              error: true,
              helperText: errors.eta,
            })}
          />
        </div>
      </div>
    </div>
  );
  const actions = (
    <>
      {duplicate && (
        <Alert severity="error">Invoice already exist in the system!</Alert>
      )}
      <Button
        variant="contained"
        className={classes.button}
        hidden={inTheCart || not_supported}
        onClick={handleAddToAutoCart}
      >
        Add Auto
      </Button>
      <Button
        variant="contained"
        className={classes.button}
        hidden={not_supported}
        onClick={handleClearAutoCart}
      >
        Clear Auto
      </Button>
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        disabled={invoice_warning === 1}
        className={classes.button}
        onClick={handleSubmit}
      >
        {duplicate ? "Submit duplicate" : "Submit"}
      </Button>
    </>
  );

  const orderTitle = (
    <>
      <Button
        style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}
        onClick={() => _ShowOrderRef.current.handleOpen(order_number, source)}
      >
        {order_number}
      </Button>
      {`(${vendor}) `}
      <IconButton
        onClick={() => navigator.clipboard.writeText(order_number)}
        aria-label="copy"
      >
        <CopyTextComponent textValue={order_number} />
      </IconButton>
      <Customer customerInfo={customerInfo} />
      <OrderInfo orderSummary={orderSummary} />
      {multiple_brand && (
        <div className="badge bg-secondary">Multiple brand</div>
      )}
    </>
  );
  return (
    <div>
      <ShowOrder ref={_ShowOrderRef} />
      <Backdrop open={working} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={orderTitle}
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
        summary={summary}
        actions={actions}
      >
        {working ? <ProductCardSkeleton /> : body}
      </Modal>
    </div>
  );
});

export default PurchaseForm;
